<template>
    <CCard>
        <CCardHeader class="d-flex justify-content-end">
            <h2 class="text-uppercase font-weight-bold mb-0">{{ title }}</h2>
            <CButton @click="add(categoryId)" class="ml-auto mr-3" color="primary">Add</CButton>
        </CCardHeader>
        <CCardBody>
            <CDataTable :items="collections" :fields="fields" :pagination="false" :loading="loading" class="mb-0">

                <template #statusDescription="data">
                    <td>
                        {{ data.item.status.description }}
                    </td>
                </template>
                <template #order="data">
                    <td>
                        {{ data.item.pivot.order }}
                    </td>
                </template>
                <template #actions="data">
                    <td>
                        <div class="d-flex flex-nowrap">
                            <CButton size="sm" color="warning" class="text-white mr-2" @click="editOrder(data.item.pivot.id, data.item.pivot.order)">Edit Order</CButton>
                            <CButton size="sm" color="danger" class="text-white mr-2"
                                @click="deleteCollection(data.item.pivot.id)">Delete</CButton>

                        </div>
                    </td>
                </template>

            </CDataTable>
        </CCardBody>
        <collection-modal ref="c_m" @operation-success="handleSuccess" />
        <delete-confirm-modal ref="delete_modal" @confirmed="confirmDelete"></delete-confirm-modal>
    </CCard>
</template>

<script>
import CollectionModal from './CollectionModal.vue';
import DeleteConfirmModal from "@/views/sessions/DeleteConfirmModal";

export default {
    name: "AddCollections",
    components: { CollectionModal, DeleteConfirmModal },
    data() {
        return {
            loadingCollectionResult: false,
            searchCollectionResult: [],
            searchCollection: '',
            title: localStorage.getItem('title') || '',
            categoryId: localStorage.getItem('categoryId'),
            collections: [],
            loading: false,
            fieldsCollectionResult: [
                { key: "title", label: "Title" },
                { key: "description", label: "Description" },
                { key: "actions", label: "Actions" }
            ],
            fields: [
                { key: "title", label: "Title" },
                { key: "description", label: "Description" },
                { key: "statusDescription", label: "Status" },
                { key: "order", label: "order" },
                { key: "actions", label: "Actions" }
            ],
            url: '/collection-categories',
            urlAddCollectionCategories: '/collection-categories-collections',
            itemsPerPage: 15,
            prevPageUrl: null,
            nextPageUrl: null
        }
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.loading = true;
            this.$http.get(`${this.url}/${this.categoryId}`).then(({ data }) => {
                this.collections = data.collections.data;
                this.loading = false;
            })
        },
        add(categoryId) {
            this.$refs.c_m.open("Add Collections to Category", categoryId);
        },
        editOrder(id, order){
            this.$refs.c_m.openEdit("Edit Collection order", id, order);
        },
        deleteCollection(id) {
            this.$refs.delete_modal.open(id);
        },
        confirmDelete(id) {
            this.loading = true;
            this.$http.delete(`${this.urlAddCollectionCategories}/${id}`)
                .then(({ data }) => {
                    this.$noty.success("Collection successfully deleted!");
                    this.loading = false;
                    this.fetchData();
                });
        },
        handleSuccess(event) {
            if (event.success) {
                //this.fetchItems(); 
            }
            if (event.add) {
                this.fetchData();
            }
        },
    }
};
</script>