<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-end align-items-center">
      <h2 class="text-uppercase font-weight-bold mb-0">List of Studios</h2>
      <CButton @click="createStudio" class="ml-auto " color="primary">Add New
      </CButton>
<!--      <CInput v-model="filters.search" @update:value="fetchData" class="mb-0" placeholder="Search"></CInput>-->
    </CCardHeader>
    <CCardBody>
      <CDataTable
          :items="data"
          :fields="c_fields"
          :pagination="false"
          :sorter="{external: true}"
          :loading="loading"
          class="mb-0"
          @update:sorter-value="sortingChange"
      >
        <template #actions="data">
          <td>
            <div class="d-flex">
              <CButton size="sm" color="warning" class="text-white mr-2" @click="editStudio(data.item.id)">Edit</CButton>
              <CButton size="sm" color="danger" class="text-white" @click="deleteStudio(data.item.id)">Delete
              </CButton>
            </div>
          </td>
        </template>
      </CDataTable>
      <pagination :current-page="options.page" @paginate="pageChange" :last-page="options.last_page" class="mr-3"/>
    </CCardBody>
    <delete-confirm-modal ref="delete_modal" @confirmed="confirmDelete"></delete-confirm-modal>
  </CCard>
</template>

<script>
import externalTable from "@/mixins/externalTable";
import DeleteConfirmModal from "./DeleteConfirmModal";

export default {
  name: "StudioList",
  components: {DeleteConfirmModal},
  mixins: [externalTable],
  data() {
    return {
      filters: {
        search: '',
      },
      fields: [
        {
          key: 'name', label: 'Name',
        },
        {
          key: 'location', label: 'Location',
        },
        {
          key: 'time_zone', label: 'Time Zone',
        },
        {
          key: 'actions', label: 'Actions',
        },
      ],
      url: '/studios'
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    createStudio() {
      this.$router.push({name: 'New Studio'});
    },
    editStudio(id) {
      this.$router.push({name: 'Edit Studio', params: {id: id}});
    },
    deleteStudio(id) {
      this.$refs.delete_modal.open(id);
    },
    confirmDelete(id) {
      this.$http.delete('/studios/' + id).then(() => {
        this.$noty.success('Deleted!');
        this.fetchData()
      }).catch(({response})=>{
        this.$noty.error(response.data.message);
      })
    },
  },
  computed: {
    c_fields() {
      return this.fields
    }
  }
}
</script>

<style scoped>

</style>