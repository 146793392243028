<template>
  <CModal
      :show.sync="showModal"
      :size="'sm'"
      centered
      @update:show="closeModal"
  >
    <template #header-wrapper>
      <CCardHeader>
        <h4 class="font-weight-bold text-center">
          Upload Build
        </h4>
      </CCardHeader>
    </template>
    <CCardBody>
      <div v-if="!file">
        <div class="d-flex flex-column align-items-center py-4" v-if="!uploading">
          <button class="btn btn-primary rounded-pill text-center text-uppercase px-5 mb-3"
                  :disabled="loading" 
                  @click="$refs.file_upload.click()">Upload Build File
          </button>
          <div class="invalid-feedback d-block mt-3" v-if="hasError('file')">
            <span class="d-block">{{ getError('file') }}</span>
          </div>
          <input class="form-control d-none" 
                 accept=".apk" 
                 type="file" 
                 @change="select" 
                 ref="file_upload">
        </div>
        <div class="row py-5" v-if="uploading">
          <div class="col-md-6 mx-auto">
            <CProgressBar></CProgressBar>
            <div class="progress">
              <div class="progress-bar" role="progressbar" :style="{width: progress + '%'}"></div>
            </div>
            <div class="d-flex justify-content-between">
              <span>{{ Math.floor(progress) }}%</span>  
              <span>100%</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="d-flex mt-3 align-items-center justify-content-center">
          <CButton color="secondary" class="mx-auto text-uppercase"
                   @click="closeModal"
          >
            Cancel
          </CButton>
        </div>
      </div>
    </CCardBody>
    <template #footer-wrapper><span> </span></template>
  </CModal>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation"

export default {
  name: "UploadBuildModal",
  mixins: [hasApiValidation],
  data() {
    return {
      showModal: false,
      file: null,
      chunks: [],
      uploaded: 0,
      uploading: false,
      loading: false,
    }
  },
  watch: {
    chunks(n) {
      if (n.length > 0) {
        this.upload();
      }
    },
    uploading(n) {
      if (n) {
        this.$noty.success('Upload Start')
      }
    }
  },
  methods: {
    open() {
      this.showModal = true;
      this.resetModal();
    },
    resetModal() {
      this.file = null;
      this.chunks = [];
      this.uploaded = 0;
      this.uploading = false;
      this.loading = false;
    },
    save() {
      // Lógica para guardar ya no es necesaria aquí, ya que se maneja automáticamente en select
    },
    closeModal() {
      this.$emit('modal:close');
    },
    select(event) {
      this.file = event.target.files.item(0); // Almacena el archivo seleccionado
      if (this.file) {
        let f_ext = this.file.name.split('.').pop();
        if (f_ext !== 'apk') {
          this.$noty.error('File not valid apk build');
          this.file = null; // Limpia el archivo si no es válido
          return;
        }
        this.startUpload(); // Inicia la subida
      }
    },
    startUpload() {
      this.uploading = true; // Cambia el estado de subida
      this.loading = true; // Cambia el estado de carga

      const formData = new FormData();
      formData.append('apk', this.file); // Agregar el archivo APK al FormData

      // Realiza la solicitud POST
      this.$http.post('/app-versions/upload', formData, {
          headers: {
              'Content-Type': 'multipart/form-data', // Configura el tipo de contenido
          },
      })
      .then(({data}) => {
        this.showModal = false;
        this.$emit('saved'); 
      })
      .catch(({response}) => {
        this.setErrors(response.data.errors); 
      })
      .finally(() => {
        this.uploading = false; 
        this.loading = false; 
        this.resetModal(); 
      });
    }
  },
  computed: {
    progress() {
      return this.file ? (this.uploaded / (this.uploaded + this.chunks.length)) * 100 : 0; 
    }
  }
}
</script>

<style scoped>

</style>
