<template>
  <CModal
    :show.sync="showModal"
    :size="'sm'"
    centered
    @update:show="closeModal"
  >
    <template #header-wrapper>
      <CCardHeader class="d-flex justify-content-between">
        <h4 class="font-weight-bold  px-3">
          New Category
        </h4>

        <CSelect
          class="mb-0 mr-3"
          :value="current_language"
          :disabled="!item"
          :options="[
            { label: 'English', value: 'en' },
            { label: 'Spanish', value: 'es' },
            { label: 'German', value: 'de' },
            { label: 'Dutch', value: 'nl' }
          ]"
          @update:value="updateLanguage"
        />
      </CCardHeader>
    </template>

    <CCardBody class="py-0 ">
      <CInput
        v-model="form.name"
        label="Name"
        :addInputClasses="{ 'is-invalid': hasError('name') }"
        :invalid-feedback="getError('name')"
      ></CInput>
      <div class="d-flex mt-3 align-items-center justify-content-between">
        <CButton
          color="secondary"
          variant="outline"
          class=" text-uppercase"
          @click="showModal = false"
        >
          Cancel
        </CButton>
        <CButton color="primary" class=" text-uppercase" @click="submit()">
          Save
        </CButton>
      </div>
    </CCardBody>
    <template #footer-wrapper><span> </span></template>
  </CModal>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: "AddCategoryModal",
  mixins: [hasApiValidation],
  data() {
    return {
      showModal: false,
      step: 1,
      finished: false,
      form: {
        name: "",
        translations: {}
      },
      item: null,
      current_language: "en",
      originData: {}
    };
  },
  computed: {
    isEnglish() {
      return this.current_language === "en";
    },
    isEdited() {
      const parser = data => JSON.stringify(data);
      return parser(this.originData) !== parser(this.form);
    }
  },
  methods: {
    setOriginData() {
      this.originData = JSON.parse(JSON.stringify(this.form));
    },
    updateLanguage(value) {
      const prevLanguage = this.current_language;

      if (this.isEdited && this.showModal) {
        const answer = window.confirm(
          "You have unsaved changes! Are you sure you want to leave the page?"
        );
        if (!answer) {
          this.current_language = null;
          this.$nextTick(() => {
            this.current_language = prevLanguage;
          });
          return;
        }
      }

      this.current_language = value;

      const currentLanguageData = this.form.translations[value] || {};

      this.form.name = currentLanguageData?.name || "";

      this.setOriginData();
    },
    setCategoryData(data) {
      this.item = data;
      this.form.name = data.name;
      this.form.translations = {
        ...data.translations,
        en: {
          name: data.name
        }
      };

      this.setOriginData();
    },
    open(category = null) {
      this.setErrors({});
      this.originData = {};
      this.item = null;
      this.form = {
        name: "",
        translations: {}
      };
      if (category) {
        this.setCategoryData(category);
      }
      this.$nextTick(() => {
        this.updateLanguage("en");
      });

      this.setOriginData();
      this.showModal = true;
    },
    submit() {
      this.setErrors({});

      if (this.item) {
        this.update();
      } else {
        this.save();
      }
    },
    update() {
      let action;

      if (this.isEnglish) {
        action = this.$http.put("/help-categories/" + this.item.id, this.form);
      } else {
        action = this.$http.post(
          `/help-categories/${this.item.id}/update-or-create-translations`,
          {
            language: this.current_language,
            name: this.form.name
          }
        );
      }

      action
        .then(({ data }) => {
          this.setCategoryData(data.data);
          this.updateLanguage(this.current_language);
          this.$emit("updated");
        })
        .catch(({ response }) => {
          this.$noty.error(response.data.message);
          this.setErrors(response.data.errors);
        });
    },
    save() {
      this.setOriginData();
      this.$http
        .post("/help-categories", this.form)
        .then(({ data }) => {
          this.setCategoryData(data.data);
          this.$emit("created");
        })
        .catch(({ response }) => {
          this.setErrors(response.data.errors);
          this.$noty.error(response.data.message);
        });
    },
    closeModal() {
      this.$emit("modal:close");
    }
  }
};
</script>

<style scoped></style>
