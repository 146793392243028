<template>
  <CCard>
    <CCardHeader class="d-flex ">
      <h2 class="mb-0" v-if="id">Edit Studio</h2>
      <h2 class="mb-0" v-else>Add Studio</h2>
    </CCardHeader>
    <CCardBody>
      <form @submit.prevent="submit" autocomplete="off" v-if="!id || loaded">
        <CInput
            label="Name"
            v-model="form.name"
            :addInputClasses="{'is-invalid': hasError('name')}"
            :invalid-feedback="getError('name')"
        ></CInput>
        <CInput
            label="Location"
            v-model="form.location"
            :addInputClasses="{'is-invalid': hasError('location')}"
            :invalid-feedback="getError('location')"
        ></CInput>

        <CSelect v-model="form.time_zone" :options="dataForSSelect(time_zones, 'Select Time Zone')" label="Time Zone"
                 @update:value="updateData($event, 'time_zone')"
                 :addInputClasses="{'is-invalid': hasError('time_zone')}"
                 :invalid-feedback="getError('time_zone')"></CSelect>
        <!--        <CButton type="button" @click="$router.push({name: 'Studios'})" color="secondary" variant="outline" class="mr-3">Back</CButton>-->
        <CButton type="submit" color="primary">Save</CButton>
      </form>
      <div class="d-flex py-5 justify-content-center align-items-center" v-else>
        <CSpinner></CSpinner>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: "AddStudio",
  mixins: [hasApiValidation],
  props: ['id'],
  data() {
    return {
      time_zones: [
        {
          value: 'America/New_York', label: 'Eastern',
        }, {
          value: 'America/Chicago', label: 'Central',
        }, {
          value: 'America/Denver', label: 'Mountain',
        }, {
          value: 'America/Phoenix', label: 'Mountain no DST',
        }, {
          value: 'America/Los_Angeles', label: 'Pacific',
        }, {
          value: 'America/Anchorage', label: 'Alaska',
        }, {
          value: 'America/Adak', label: 'Hawaii',
        }, {
          value: 'Pacific/Honolulu', label: 'Hawaii no DST'
        },
      ],
      form: {
        name: '',
        location: null,
        time_zone: null,
      },
      loaded: false,
    }
  },
  mounted() {
    if (this.id) {
      this.fetchData();
    }
  },
  methods: {
    submit() {
      this.setErrors({});
      this.loading = true;
      if (this.id) {
        this.update();
      } else {
        this.save();
      }
    },
    fetchData() {
      this.$http.get('/studios/' + this.id).then(({data}) => {
        this.form.name = data.data.name;
        this.form.location = data.data.location;
        this.form.time_zone = data.data.time_zone;
        this.loading = false;
        this.loaded = true;
      }).catch(({response}) => {
        this.loading = false;
        this.$router.push({name: 'Studios'}).then(()=>{
          this.$noty.error('This studio is not available')
        })
      })
    },
    dataForSSelect(data, name = "Select") {
      let arr = [{
        value: null,
        label: name,
        disabled: true,
      }]
      data.forEach(item => {
        arr.push({
          value: item.value,
          label: item.label + ' ('+item.value+')',
          // text: item.name
        })
      })
      return arr
    },
    updateData(data, type) {
      this.form[type] = data;
    },
    save() {
      this.$http.post('/studios', this.form).then((data) => {
        this.$noty.success('Created');
        this.$router.push({name: 'Studios'})
        this.loading = false;
      }).catch(({response}) => {
        this.$noty.error(response.data.message);
        this.setErrors(response.data.errors);
        this.loading = false;
      })
    },
    update() {
      this.$http.put('/studios/' + this.id, this.form).then((data) => {
        this.$noty.success('Updated');
        this.$router.push({name: 'Studios'})
        this.loading = false;
      }).catch(({response}) => {
        if(response.status === 404){
          this.$router.push({name: 'Studios'}).then(()=>{
            this.$noty.error('This studio is not available')
          })
        } else {
          this.$noty.error(response.data.message);
          this.loading = false;
          this.setErrors(response.data.errors);
        }
      })
    },
  },
}
</script>

<style scoped>

</style>