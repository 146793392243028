<template>
  <CModal
      :show.sync="showModal"
      centered
      @update:show="closeModal"
      title="Filters"
  >
    <label>Status</label>
    <CMultiSelect
        v-if="showModal"
        :options="dataForSelect"
        :selected="filters.status"
        :selection="true"
        inline
        optionsEmptyPlaceholder="No options"
        searchPlaceholder="Search"
        selectionType="tags"
        @update="updateData"
    ></CMultiSelect>
    <template #footer-wrapper>
      <div class="d-flex my-3 align-items-center justify-content-center">
        <CButton color="primary" class="mx-auto"
                 @click="apply"
        >
          Apply
        </CButton>
      </div>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "FiltersModal",
  data() {
    return {
      statuses: [
        {value: 0, label: 'Pending'},
        {value: 1, label: 'Active'},
        {value: 2, label: 'Rejected'},
        {value: 3, label: 'Blocked'},
        {value: 4, label: 'Invited'},
      ],
      filters: {
        search: '',
        status: [],
      },
      items: [],
      showModal: false,
      step: 1,
      finished: false,
    }
  },
  methods: {
    open(filters) {
      document.querySelector('body').classList.add('modal_open')
      this.showModal = true;
      Object.assign(this.filters, filters);
    },
    closeModal() {
      this.$emit('modal:close');
      document.querySelector('body').classList.remove('modal_open')
    },
    apply() {
      this.showModal = false;
      this.$emit('apply', this.filters);
      document.querySelector('body').classList.remove('modal_open')
    },
    updateData(data) {
      this.filters.status = data;
    }
  },
  computed: {
    dataForSelect() {
      return this.statuses.map(item => {
        return {
          value: item.value,
          text: item.label
        }
      })
    },
  }
}
</script>

<style scoped>
</style>