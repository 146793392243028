<template>
  <form class="annotation_form">
    <div class="row">
      <div class="col-md-2">
        <CInput v-model="new_annotation.name" label="Name" class="flex-grow-1"
                    :invalidFeedback="getError('name')"
                    disabled
        ></CInput>
      </div>
      <div class="col-md-2">
        <c-form-group :invalidFeedback="getError('instant')">
          <template #label><label class="fw-medium">Time</label></template>
          <template #input>
          <the-mask v-model="start_segment"
                    placeholder="00:00"
                    class="form-control"
                    :masked="true"
                    mask="##:##"
          ></the-mask>
          </template>
        </c-form-group>
      </div>
      <div class="col-md-4">
        <CInput v-model="new_annotation.description" label="Description"
                    :invalidFeedback="getError('description')"
        ></CInput>
      </div>
      <div class="col-auto col-sm-3 col-lg-2 ml-auto d-flex align-items-center justify-content-end">
        <span class="mr-3 annotation_icons" @click="saveAnnotation()"><i class="fa fa-check "></i></span>
        <span class="annotation_icons" @click="deleteAnnotation()"><i class="fa fa-trash "></i></span>
      </div>
    </div>
  </form>
</template>
<script>
import hasApiValidation from "@/mixins/hasApiValidation";
import {mask} from 'vue-the-mask'
import {TheMask} from 'vue-the-mask'

export default {
  name: 'AudioAnnotationForm',
  components: {TheMask},
  mixins: [hasApiValidation],
  directives: {mask},
  props: {
    id: {
      required: true
    },
    annotation: {
      type: Object,
    },
    max: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      new_annotation: {
        instant: null,
        id: null,
        name: null,
        description:null,
      }
    }
  },
  mounted() {
    if (this.annotation) {
      this.new_annotation = Object.assign({}, this.annotation);
    }
  },
  methods: {
    deleteAnnotation() {
      this.$emit('delete', this.new_annotation);
    },
    updateValue(start, time) {
      this.new_annotation[time] = start;
    },
    updateName(name) {
      this.new_annotation.name = name;
    },
    saveAnnotation() {
      this.setErrors({});
      if (this.new_annotation.id) {
        this.$http.put(`/sessions/${this.id}/annotations/${this.new_annotation.id}`, this.new_annotation).then(({data}) => {
          this.setErrors({});
          this.$emit('updated', data.data);
        }).catch(({response}) => {
          this.setErrors(response.data.errors);
        })
      } else {
        this.$http.post(`/sessions/${this.id}/annotations`, this.new_annotation).then(({data}) => {
          this.setErrors({});
          this.$emit('saved', data.data);
        }).catch(({response}) => {
          this.setErrors(response.data.errors);
        })
      }
    },
    parseValue(event) {
      let a = event.split(':'); // split it at the colons
      let seconds = (+parseInt(a[0])) * 60 + (+parseInt(a[1]));
      if (seconds > this.max) {
        this.new_annotation.instant = Math.floor(this.max);
      } else {
        this.new_annotation.instant = seconds;
      }
    },
  },
  computed:{
    start_segment: {
      get: function () {
        if (this.new_annotation.instant) {
          let minutes = Math.floor((this.new_annotation.instant / 60));
          let seconds = this.new_annotation.instant - (minutes * 60);

          if (minutes < 10) {
            minutes = "0" + minutes;
          }
          if (seconds < 10) {
            seconds = "0" + seconds;
          }
          return minutes + ':' + seconds;
        } else {
          return '00:00'
        }
      },
      set: function (val) {
        this.parseValue(val)
      },
    },
  },
  watch: {
    'new_annotation.instant': {
      handler: function (val) {
        if (val > this.max) {
          this.new_annotation.instant = Math.floor(this.max);
        }
      }
    },
  }

}
</script>
<style scoped>
.annotation_icons {
  width: 26px;
  height: 26px;
  background-color: var(--primary);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

</style>