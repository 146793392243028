var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',{staticClass:"d-flex "},[(!_vm.id)?_c('h2',{staticClass:"mb-0"},[_vm._v("New Collection")]):_c('h2',{staticClass:"mb-0"},[_vm._v("Edit Collection")])]),_c('CCardBody',[((!this.id || !this.loading))?_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('CInput',{attrs:{"label":"Title","addInputClasses":{ 'is-invalid': _vm.hasError('title') },"invalid-feedback":_vm.getError('title')},model:{value:(_vm.new_collection.title),callback:function ($$v) {_vm.$set(_vm.new_collection, "title", $$v)},expression:"new_collection.title"}}),_c('CCard',{staticClass:"mb-3",staticStyle:{"height":"25rem"}},[_c('CCardTitle',[_vm._v("Image")]),(_vm.new_collection.image_base64 || _vm.new_collection.image_url)?_c('CCardImg',{staticStyle:{"height":"20rem"},attrs:{"orientation":"top","src":_vm.new_collection.image_base64 || _vm.new_collection.image_url}}):_vm._e(),_c('CCardHeader',[_c('input',{attrs:{"type":"file","label":"Image"},on:{"input":function($event){return _vm.onFileChange($event)}}})])],1),(_vm.hasError('image'))?_c('div',{staticClass:"invalid-feedback d-block"},[_vm._v(" "+_vm._s(_vm.getError("image"))+" ")]):_vm._e(),((this.id))?_c('CInput',{attrs:{"label":"Release Date"},model:{value:(_vm.new_collection.release_at),callback:function ($$v) {_vm.$set(_vm.new_collection, "release_at", $$v)},expression:"new_collection.release_at"}}):_vm._e()],1),_c('CCol',{attrs:{"lg":"6"}},[_c('label',[_vm._v("Description")]),_c('editor',{attrs:{"api-key":_vm.tiny_key,"init":{
                            height: 500,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | removeformat | help'
                        }},model:{value:(_vm.new_collection.description),callback:function ($$v) {_vm.$set(_vm.new_collection, "description", $$v)},expression:"new_collection.description"}}),(_vm.hasError('description'))?_c('div',{staticClass:"invalid-feedback d-block"},[_vm._v(" "+_vm._s(_vm.getError("description"))+" ")]):_vm._e()],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6"}})],1),_c('CButton',{staticClass:"mt-3",attrs:{"type":"submit","color":"primary"}},[_vm._v("Save")])],1):_c('div',{staticClass:"mb-3"},[_c('CSpinner',{staticStyle:{"width":"2rem","height":"2rem"},attrs:{"color":"primary","grow":""}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }