import {validationMixin} from 'vuelidate'

export default {
    mixins: [validationMixin],

    data() {
        return {
            errors: {},
        }
    },
    methods: {
        setErrors(errors) {
            this.errors = errors;
        },
        hasError(key) {
            return (this.errors && !!this.errors[key]);
        },
        getError(key) {
            return (this.errors && this.errors[key]) ?
                (typeof this.errors[key] === 'string') ? this.errors[key] : this.errors[key][0]
                : "";
        },
        getArrayErrors(key) {
            let keys = Object.keys(this.errors);
            let errs = [];
            keys.forEach(k => k.startsWith(key) ? errs.push(this.errors[k]) : null)
            return errs
        },
        checkState(key) {
            return (this.errors && !!this.errors[key]) ? false : null;
        },
        clearError(key) {
            delete this.errors[key]
        },
        validate() {
            return true;
        },
    },
};
