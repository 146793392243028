<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-end">
      <h2 class="text-uppercase font-weight-bold mb-0">List of Categories</h2>
      <CButton @click="newCollectionCategory" class="ml-auto mr-3" color="primary" >Add New</CButton>

      <!-- <CButton @click="openFilters" class="mr-3" color="primary">Filters</CButton> -->
      <!-- <CInput v-model="filters.search" @update:value="fetchData" class="mb-0" placeholder="Search"></CInput> -->
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :items="data" 
        :fields="fields"  
        :pagination="false" 
        :loading="loading" 
        class="mb-0"
      >
      <template #actions="data">
          <td>
            <div class="d-flex flex-nowrap">
              <CButton size="sm" color="warning" class="text-white mr-2" @click="editCollectionCategory(data.item.id)">Edit</CButton>
              <CButton size="sm" color="danger" class="text-white mr-2" @click="deleteCollectionCategory(data.item.id)">Delete</CButton>
              <CButton size="sm" color="success" class="text-white mr-2" @click="addCollections(data.item.id, data.item.title)">Collections...</CButton>
            </div>
          </td>
        </template>

    </CDataTable>
    <div class="pagination-controls"> 
        <CButton @click="prevPage" color="primary" :disabled="!prevPageUrl">Previous</CButton> 
        <CButton @click="nextPage" color="primary" :disabled="!nextPageUrl">Next</CButton> 
    </div>
    </CCardBody>
    <delete-confirm-modal ref="delete_modal" @confirmed="confirmDelete"></delete-confirm-modal>
  </CCard>
</template>

<script>

import DeleteConfirmModal from "@/views/sessions/DeleteConfirmModal";

export default {
  name: "Categories",
  components: {DeleteConfirmModal},
  props: ['currentPage'],
  data() {
    return {
      data: [], 
      loading: true, 
      fields: [
        { key: "title", label: "Title" },
        { key: "description", label: "Description" },
        { key: "collections_count", label: "Collections"},
        { key: "order", label: "Order" },
        { key: "actions", label: "Actions", sortable: false },
      ],
      url: '/collection-categories',
      itemsPerPage: 15, 
      prevPageUrl: null, 
      nextPageUrl: null
    };
  },
  mounted() {
    this.fetchData(this.currentPage, this.itemsPerPage); 
  },
  methods: {
    addCollections(id, categoryTitle) {
      localStorage.setItem('title', `Collections (${categoryTitle})`); 
      localStorage.setItem('categoryId', id); 
      this.$router.push({name: 'List collections by category'});
    },
    confirmDelete(id) {
      this.$http.delete(`${this.url}/${id}`).then(() => {
        this.$noty.success('Deleted!');
        this.fetchData(this.currentPage, this.itemsPerPage);
      });
    },
    deleteCollectionCategory(id){
      this.$refs.delete_modal.open(id);
    },
    prevPage() { 
      if (this.prevPageUrl) { 
        const urlParams = new URLSearchParams(this.prevPageUrl.split('?')[1]); 
        const page = urlParams.get('page'); 
        this.currentPage = page;
        this.fetchData(page, this.itemsPerPage); 
      } 
    }, 
    nextPage() { 
      if (this.nextPageUrl) { 
        const urlParams = new URLSearchParams(this.nextPageUrl.split('?')[1]); 
        const page = urlParams.get('page'); 
        this.currentPage = page;
        this.fetchData(page, this.itemsPerPage); 
      } 
    },
    editCollectionCategory(id){
        this.$router.push({name: 'Edit Collection Category', params: {id: id, currentPage: this.currentPage}});
    },
    newCollectionCategory(){
        this.$router.push({name: 'New Collection Category'});
    },
    fetchData(page,itemsPerPage) {
      this.loading = true;
      this.$http.get(`${this.url}?page=${page}&pageItems=${itemsPerPage}`).then(({data}) => {
        this.data = data.data;
        this.prevPageUrl = data.prev_page_url; 
        this.nextPageUrl = data.next_page_url;
      })
      .catch(({response}) => {
        if (response && response.data && response.data.message) {
          this.$noty.error(response.data.message)
        }
      })
      .finally(() => {
        this.loading = false;
      });
    },
    // Format date for display
    formatDate(dateString) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };
      return new Date(dateString).toLocaleString("en-US", options);
    }
  },
};

</script>

<style scoped>
.pagination-controls { 
  display: flex; 
  justify-content: center; 
  gap: 1rem; 
} 
 
CButton[disabled] { 
  opacity: 0.5; 
  pointer-events: none; 
}
</style>
