<template>
    <CModal :show.sync="showModal" centered :title="title" size="xl" :visible="true" @update:show="closeModal">
        <div v-if="loadingAdd">
            <CSpinner style="width:2rem;height:2rem;" color="primary" grow />
        </div>
        <CCardBody class="pt-0">
            <div v-if="!loadingAdd && id === null">
                <CRow>
                    <CCol lg="12" class="d-flex justify-content-between align-items-center">
                        <div class="w-100 mx-auto">
                            <CInput label="Search collection" class="w-100" v-model="searchCollection" />
                        </div>
                        <CButton color="primary" class="ml-3" style="margin-top: 0.9rem;" @click="search()">
                            Search
                        </CButton>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol lg="12">
                        <CDataTable :items="searchCollectionResult" :fields="fieldsCollectionResult" :pagination="true"
                            :loading="loadingCollectionResult" :items-per-page="3" class="mb-0">
                            <template #actions="data">
                                <td>
                                    <div class="d-flex flex-nowrap">
                                        <CButton size="sm" color="warning" class="text-white mr-2"
                                            @click="addToCollection(data.item.id)">Add</CButton>
                                    </div>
                                </td>
                            </template>

                        </CDataTable>
                    </CCol>

                </CRow>
            </div>
            <div v-if="id && !loadingAdd">
                <CRow>
                    <CCol lg="12">
                        <CInput label="Order" v-model="order" />
                    </CCol>
                </CRow>

            </div>

            <div class="d-flex mt-3 align-items-center justify-content-center">
                <CButton color="secondary" class="mx-auto text-uppercase" @click="showModal = false">Close</CButton>
                <CButton  v-if="id && !loadingAdd" color="secondary" class="mx-auto text-uppercase" @click="updatedOrder()">Save</CButton>
            </div>
        </CCardBody>
        <template #footer-wrapper>
            <div class="d-flex my-0 align-items-center justify-content-center">
            </div>
        </template>

    </CModal>
</template>

<script>
export default {
    name: "CollectionModal",
    data() {
        return {
            loadingAdd: false,
            categoryId: null,
            showModal: false,
            title: '',
            loadingCollectionResult: false,
            searchCollectionResult: [],
            searchCollection: '',
            id: null,
            order: null,
            collectionCategoriesCollections: {
                collection_id: null,
                category_id: null,
                order: 1
            },
            fieldsCollectionResult: [
                { key: "title", label: "Title" },
                { key: "description", label: "Description" },
                { key: "actions", label: "Actions" }
            ],
            url: '/collections',
            urlAddCollectionCategories: '/collection-categories-collections'
        }
    },
    methods: {
        closeModal() {

        },
        updatedOrder(){
            if (this.id) {
                this.loadingAdd = true;
                this.$http.put(`${this.urlAddCollectionCategories}/${this.id}/order`, { order :this.order} )
                    .then(({ data }) => {
                        this.$noty.success("Order successfully saved!");
                        this.$emit('operation-success', { add: true });
                        this.loadingAdd = false;
                        this.showModal = false;
                    });
            }
        },
        addToCollection(collectionId) {
            this.loadingAdd = true;
            this.collectionCategoriesCollections.category_id = this.categoryId;
            this.collectionCategoriesCollections.collection_id = collectionId;

            this.$http.post(`${this.urlAddCollectionCategories}`, this.collectionCategoriesCollections)
                .then(({ data }) => {
                    this.$noty.success("Collection successfully added!");
                    this.$emit('operation-success', { add: true });
                    this.loadingAdd = false;
                })
                .catch(({ response }) => {
                    this.$noty.error("The collection already exists in the category!");
                    this.loadingAdd = false;
                });
        },
        search() {
            this.loadingCollectionResult = true;
            this.$http.get(`${this.url}?title=${this.searchCollection}`)
                .then(({ data }) => {
                    this.searchCollectionResult = data.data;
                    this.loadingCollectionResult = false;
                });
        },
        openEdit(title, id,  order) {
            this.showModal = true;
            this.id = id;
            this.title = title;
            this.order = order;
        },
        open(title, categoryId) {
            this.id = null;
            this.showModal = true;
            this.title = title;
            this.categoryId = categoryId;

        }
    }

}
</script>

<style scoped></style>