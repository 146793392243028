<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-end">
      <h2 class="text-uppercase font-weight-bold mb-0">User {{
          subscriptions ? 'subscription cancel' : 'account delete'
        }} reasons</h2>
      <CButton @click="swapFields" class="mr-auto ml-3" color="primary">
        <span v-if="!subscriptions">Subscription cancel reasons</span>
        <span v-else>Account delete reasons</span>
      </CButton>
    </CCardHeader>
    <CCardBody>
      <CDataTable
          :items="data"
          :fields="c_fields"
          :pagination="false"
          :sorter="{external: true}"
          :loading="loading"
          class="mb-0"
          @update:sorter-value="sortingChange"
      >
        <template #created_at="{item}">
          <td>
            {{$moment(item.created_at).format('YYYY-MM-DD')}}
          </td>
        </template>
        <template #actions="{item}">
          <td>
            <div class="d-flex">
              <CButton size="sm" color="danger" class="text-white" @click="deleteR(item.id)">Delete</CButton>
            </div>
          </td>
        </template>
      </CDataTable>
      <pagination :current-page="options.page" @paginate="pageChange" :last-page="options.last_page" class="mr-3"/>
    </CCardBody>
    <delete-confirm-modal ref="delete_modal" @confirmed="destroyR"></delete-confirm-modal>
  </CCard>
</template>

<script>
import externalTable from "@/mixins/externalTable";
import FiltersModal from "../instructors/FiltersModal";
import DeleteConfirmModal from "../instructors/DeleteConfirmModal";

export default {
  name: "SubscriptionCancelReasons",
  components: {DeleteConfirmModal, FiltersModal},
  mixins: [externalTable],
  data() {
    return {
      filters: {
        search: '',
        status: [],
      },
      subscriptions: true,
      fields: [
        {
          key: 'user_name', label: 'Name',
        },
        {
          key: 'user_email', label: 'Email',
        },
        {
          key: 'reason', label: 'Reason',
        },
      ],
      url: '/subscription-cancel-reasons'
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    swapFields() {
      this.options.page = 1;
      this.subscriptions = !this.subscriptions;
      if (this.subscriptions) {
        this.url = '/subscription-cancel-reasons'
      } else {
        this.url = '/account-delete-reasons'
      }
      this.fetchData();
    },
    deleteR(id) {
      this.$refs.delete_modal.open(id);
    },
    destroyR(id) {
      this.$http.delete(this.url + '/' + id + '').then(() => {
        this.$noty.success('Deleted!');
        this.fetchData()
      })
    },
  },
  computed: {
    c_fields() {
      let fields = this.fields.slice(0);
      fields.push(
          {
            key: 'created_at', label: this.subscriptions ? 'Canceled' : 'Deleted',
          },)
      fields.push(
          {
            key: 'actions', label: 'Actions',
          });
      return fields
    }
  }
}
</script>

<style scoped>

</style>