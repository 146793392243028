<template>
  <CCard>
    <CCardHeader class="d-flex ">
      <h2 class="text-uppercase font-weight-bold mb-0">List of Permissions</h2>
    </CCardHeader>
    <CCardBody>
      <div class="table-responsive">

        <table class="table" v-if="load_steps === 2">
          <thead>
          <tr>
            <th >Group</th>
            <th >Permission</th>
            <th v-for="role in roles" :key="'th-role-'+role.id" class="text-nowrap">{{ role.name }}</th>
          </tr>
          </thead>
          <template v-for="group in groups">
            <tr v-for="(permission,p_index) in group.permissions">
              <td v-if="p_index === 0" :rowspan="group.permissions.length" class="text-nowrap">{{ group.label }}</td>
              <td class="text-nowrap">{{ permission.label }}</td>
              <th v-for="role in roles" :key="'th-role-'+role.id" class="text-center">
                <CSpinner
                    style="width:1rem;height:1rem;"
                    color="primary"
                    grow
                    v-if="processing.role === role.id && processing.permission === permission.name"
                />
                <input type="checkbox" :checked="role.permissions.includes(permission.name)"
                       @change="updateRole(role, permission)" v-show="!(processing.role === role.id && processing.permission === permission.name)"
                      :disabled="processing.role && processing.permission"
                >
              </th>
            </tr>
          </template>
        </table>
        <template v-else>
          <div class="d-flex justify-content-center">

            <CSpinner
                style="width:2rem;height:2rem;"
                color="primary"
                grow
            />
          </div>
        </template>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: "Permissions",
  data() {
    return {
      roles: [],
      groups: [],
      load_steps: 2,
      processing: {
        role: null,
        permission: null
      }
    }
  },
  mounted() {
    this.getRoles();
    this.getPermissionGroups();
  },
  methods: {
    getRoles() {
      this.load_steps--;
      this.$http.get('/admins/roles').then(({data}) => {
        this.roles = data.data;
      }).finally(() => {
        this.load_steps++;
      })
    },
    getPermissionGroups() {
      this.load_steps--;
      this.$http.get('/permissions').then(({data}) => {
        this.groups = data.data;
      }).finally(() => {
        this.load_steps++;
      })
    },
    updateRole(role, permission) {
      this.processing = {
        role: role.id,
        permission: permission.name
      }
      this.$http.put('/roles/' + role.id + '/permission', {permission: permission.name})
          .then(() => {
            this.$noty.success('Updated!');
          })
          .finally(() => {
            this.processing = {
              role: null,
              permission: null
            }
            this.$http.get('/admins/roles').then(({data}) => {
              this.roles = data.data;
            })
          })
    }
  }
}
</script>

<style scoped>

</style>