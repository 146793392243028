<template>
  <CModal
      :show.sync="showModal"
      centered
      @update:show="closeModal"
      title="Filters"
  >
    <!--    <CSelect multiple v-model="filters.roles" :options="dataForSelect(roles)" label="Roles"></CSelect>-->
    <label>Roles</label>
    <CMultiSelect
        v-if="!loading_roles"
        :options="dataForSelect"
        :selected="filters.roles"
        :selection="true"
        inline
        optionsEmptyPlaceholder="No options placeholder"
        searchPlaceholder="Search"
        selectionType="tags"
        @update="updateData"
    ></CMultiSelect>
    <template #footer-wrapper>
      <div class="d-flex my-3 align-items-center justify-content-center">
        <CButton color="primary" class="mx-auto"
                 @click="apply"
        >
          Apply
        </CButton>
      </div>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "FiltersModal",
  data() {
    return {
      filters: {
        search: '',
        roles: [],
      },
      items: [],
      showModal: false,
      step: 1,
      finished: false,
      loading_roles: true,
      roles: [],
    }
  },
  methods: {
    open(filters) {
      document.querySelector('body').classList.add('modal_open')
      this.showModal = true;
      Object.assign(this.filters, filters);
      this.roles = [];
      this.loading_roles = true;
      this.getRoles();
    },
    closeModal() {
      this.$emit('modal:close');
      document.querySelector('body').classList.remove('modal_open')
    },
    apply() {
      this.showModal = false;
      this.$emit('apply', this.filters);
      document.querySelector('body').classList.remove('modal_open')
    },
    getRoles() {
      this.loading_roles = true;
      this.$http.get('/admins/roles').then(({data}) => {
        this.roles = data.data;
        if (this.filters.roles.length > 0) {
          this.filters.roles = this.filters.roles.filter(role_id => {
            return this.roles.map(item => item.id).includes(role_id);
          })
        }
      }).finally(() => {
        this.loading_roles = false;
      })
    },
    updateData(data) {
      this.filters.roles = data;
    }
  },
  computed: {
    dataForSelect() {
      return this.roles.map(item => {
        return {
          value: item.id,
          text: item.name
        }
      })
    },
  }
}
</script>

<style scoped>
</style>