<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-end">
      <h2 class="text-uppercase font-weight-bold mb-0">List of Admins</h2>
      <CButton @click="createAdmin" class="ml-auto mr-3" color="primary" v-if="$auth.check('admins_crud')">Add New</CButton>
      <CButton @click="openFilters" class="mr-3" color="primary">Filters</CButton>

      <CInput v-model="filters.search" @update:value="fetchData" class="mb-0" placeholder="Search"></CInput>
    </CCardHeader>
    <CCardHeader v-if="filters.roles.length > 0">
      Roles:
        <span v-for="role in filters.roles"
              :key="'f-role-'+role"
              class="badge bg-info text-white mr-2 h6 c-pointer" @click="removeRoleFilter(role)">
        {{roles.find(r => r.id === role) ? roles.find(r => r.id === role).name : '' }} <i class="fas fa-times"></i>
      </span>
    </CCardHeader>
    <CCardBody>
      <CDataTable
          :items="data"
          :fields="c_fields"
          :pagination="false"
          :sorter="{external: true}"
          :loading="loading"
          class="mb-0"
          @update:sorter-value="sortingChange"
      >
        <template #roles="data">
          <td>
            {{ data.item.roles.map(item => item.name).join(', ') }}
          </td>
        </template>
        <template #status="data">
          <td>
            {{ data.item.status_name}}
          </td>
        </template>
        <template #actions="data">
          <td>
            <div class="d-flex" v-if="!data.item.sa">
              <CButton size="sm" color="warning" class="text-white mr-2" @click="editAdmin(data.item.id)">Edit</CButton>
              <CButton size="sm" color="danger" class="text-white  mr-2" @click="blockAdmin(data.item.id)"
                       v-if="data.item.status === 1">Block
              </CButton>
              <CButton size="sm" color="success" class="text-white mr-2" @click="unBlockAdmin(data.item.id)" v-if="data.item.status === 2">Unblock
              </CButton>
              <CButton size="sm" color="danger" class="text-white" @click="deleteAdmin(data.item.id)">Delete</CButton>
            </div>
          </td>
        </template>
      </CDataTable>
      <pagination :current-page="options.page" @paginate="pageChange" :last-page="options.last_page" class="mr-3"/>
    </CCardBody>
    <filters-modal ref="filters_modal" @apply="changeFilters"></filters-modal>
    <delete-confirm-modal ref="block_modal" @confirmed="confirmBlockAdmin"></delete-confirm-modal>
    <delete-confirm-modal ref="delete_modal" @confirmed="confirmDeleteAdmin"></delete-confirm-modal>
  </CCard>
</template>

<script>
import externalTable from "@/mixins/externalTable";
import FiltersModal from "@/views/admins/FiltersModal";
import DeleteConfirmModal from "@/views/admins/DeleteConfirmModal";

export default {
  name: "Admins",
  components: {DeleteConfirmModal, FiltersModal},
  mixins: [externalTable],
  data() {
    return {
      filters: {
        search: '',
        roles: [],
      },
      roles: [],
      fields: [
        {
          key: 'first_name', label: 'First Name',
        },
        {
          key: 'last_name', label: 'Last Name',
        },
        {
          key: 'email', label: 'Email',
        },
        {
          key: 'roles', label: 'Roles', sorter: false,
        },
        {
          key: 'status', label: 'Status',
        },
      ],
      url: '/admins'
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    removeRoleFilter(role){
      this.filters.roles = this.filters.roles.filter(r => r !== role);
      this.fetchData();
    },
    openFilters() {
      this.$refs.filters_modal.open(this.filters);
    },
    changeFilters(data) {
      this.filters = Object.assign({}, this.filters, data);
      this.fetchData();
      this.getRoles();
    },
    createAdmin() {
      this.$router.push({name: 'New Admin'});
    },
    editAdmin(id) {
      this.$router.push({name: 'Edit Admin', params: {id: id}});
    },
    blockAdmin(id) {
      this.$refs.block_modal.open(id);
    },
    deleteAdmin(id) {
      this.$refs.delete_modal.open(id);
    },
    unBlockAdmin(id) {
      this.$http.put('/admins/' + id + '/unblock').then(() => {
        this.$noty.success('Unblocked!');
        this.fetchData()
      })
    },
    getRoles() {
      this.$http.get('/admins/roles').then(({data}) => {
        this.roles = data.data;
      })
    },
    confirmDeleteAdmin(id) {
      this.$http.delete('/admins/' + id).then(() => {
        this.$noty.success('Deleted!');
        this.fetchData()
      })
    },
    confirmBlockAdmin(id) {
      this.$http.put('/admins/' + id + '/block').then(() => {
        this.$noty.success('Blocked!');
        this.fetchData()
      })
    },
  },
  computed: {
    c_fields() {
      let fields = this.fields.slice(0);
      if (this.$auth.check('admins_crud')) {
        fields.push(
            {
              key: 'actions', label: 'Actions',
            });
      }
      return fields
    }
  }
}
</script>

<style scoped>

</style>