import {
} from '@coreui/icons'

import { logo } from './logo'
export const iconsSet = Object.assign(
    {},
    { logo },
    {
    }
)
