<template>
  <CCard>
    <CCardHeader class="d-flex ">
      <h2 v-if="!id" class="mb-0">New User</h2>
      <h2 v-else class="mb-0">Edit User</h2>
    </CCardHeader>
    <CCardBody>
      <form @submit.prevent="submit" autocomplete="off" v-if="!this.id || !this.user_loading">
        <CRow>
          <CCol md="6" lg="4">
            <CInput
                label="Name"
                v-model="new_user.name"
                autocomplete="name"
                :addInputClasses="{'is-invalid': hasError('name')}"
                :invalid-feedback="getError('name')"
            ></CInput>
          </CCol>
          <CCol md="6" lg="4">
            <CInput
                label="Email"
                v-model="new_user.email"
                autocomplete="email"
                :addInputClasses="{'is-invalid': hasError('email')}"
                :invalid-feedback="getError('email')"
            ></CInput>
          </CCol>
          <CCol md="6" lg="4">
            <CInput
                label="Country"
                v-model="new_user.country"
                :addInputClasses="{'is-invalid': hasError('country')}"
                :invalid-feedback="getError('country')"
            ></CInput>
          </CCol>
          <CCol md="6" lg="4">
            <CInput
                label="City"
                v-model="new_user.city"
                :addInputClasses="{'is-invalid': hasError('city')}"
                :invalid-feedback="getError('city')"
            ></CInput>
          </CCol>
          <CCol md="6" lg="4">
            <CInput
                label="Zip Code"
                v-model="new_user.zip"
                :addInputClasses="{'is-invalid': hasError('zip')}"
                :invalid-feedback="getError('zip')"
            ></CInput>
          </CCol>
          <CCol md="6" lg="4">
            <CInput
                label="Phone"
                v-model="new_user.phone"
                :addInputClasses="{'is-invalid': hasError('phone')}"
                :invalid-feedback="getError('phone')"
            ></CInput>
          </CCol>
          <CCol md="6" lg="4">
            <CInput
                label="Address Line 1"
                v-model="new_user.address_line_1"
                :addInputClasses="{'is-invalid': hasError('address_line_1')}"
                :invalid-feedback="getError('address_line_1')"
            ></CInput>
          </CCol>
          <CCol md="6" lg="4">
            <CInput
                label="Address Line 2"
                v-model="new_user.address_line_2"
                :addInputClasses="{'is-invalid': hasError('address_line_2')}"
                :invalid-feedback="getError('address_line_2')"
            ></CInput>
          </CCol>
          <CCol md="6" lg="4">
            <CInput
                label="Address Line 3"
                v-model="new_user.address_line_3"
                :addInputClasses="{'is-invalid': hasError('address_line_3')}"
                :invalid-feedback="getError('address_line_3')"
            ></CInput>
          </CCol>
        </CRow>
        <CButton type="submit" color="primary">Save</CButton>
      </form>
      <div v-else class="mb-3">
        <CSpinner
            style="width:2rem;height:2rem;"
            color="primary"
            grow
        />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: "EditEnterpriseUser",
  mixins: [hasApiValidation],
  props: ['id'],
  data() {
    return {
      roles: [],
      new_user: {
        name: '',
        email: '',
        address_line_1: '',
        address_line_2: '',
        address_line_3: '',
        phone: '',
        zip: '',
        city: '',
        country:'',
      },
      loading_roles: true,
      user_loading: false,
    }
  },
  mounted() {
    this.user_loading = true;
    if (this.id) {
      this.fetchUser(this.id);
    }
  },
  methods: {
    fetchUser(id) {
      this.$http.get('/enterprise-users/' + id).then(({data}) => {
        this.new_user.id = data.data.id
        this.new_user.name = data.data.name;
        this.new_user.email = data.data.email;
        this.new_user.address_line_1 = data.data.address_line_1;
        this.new_user.address_line_2 = data.data.address_line_2;
        this.new_user.address_line_3 = data.data.address_line_3;
        this.new_user.country = data.data.country;
        this.new_user.phone = data.data.phone;
        this.new_user.zip = data.data.zip;
        this.new_user.city = data.data.city;
      }).catch(({response}) => {
        this.$router.push({name: 'Enterprise Users'}).then(() => {
          this.$noty.error(response.data.message);
        })
      }).finally(() => {
        this.user_loading = false;
      })
    },
    submit() {
      if (this.id) {
        this.update();
      } else {
        this.save();
      }
    },
    save() {
      this.setErrors({});
      this.$http.post('/enterprise-users/', this.new_user).then((data) => {
        this.$noty.success('Created');
        this.setErrors({});
        this.$router.push({name: 'Enterprise Users'})
      }).catch(({response}) => {
        this.$noty.error(response.data.message);
        this.setErrors(response.data.errors);
      })
    },
    update() {
      this.$http.put('/enterprise-users/' + this.id, this.new_user).then((data) => {
        this.$noty.success('Updated');
        this.$router.push({name: 'Enterprise Users'})
      }).catch(({response}) => {
        this.$noty.error(response.data.message);
        this.setErrors(response.data.errors);
      })
    }
  },
}
</script>

<style scoped>

</style>