<template>
    <CModal
     :show.sync="showModal"
      centered
      @update:show="closeModal"
      :title="title"
      size="lg"      
    >
    <div v-if="loadinEdit">
        <CSpinner
            style="width:2rem;height:2rem;"
            color="primary"
            grow
        />
    </div>
        <CCardBody v-else-if="!loadinEdit" class="pt-0" >
            <CRow>
                <CCol lg="12">
                    <CInput label="Title" v-model="new_divider.title" />
                    <div class="invalid-feedback d-block" v-if="hasError('title')">
                        {{ getError("title") }}
                    </div>
                </CCol>
            </CRow>
            <CRow>
                <CCol lg="12">
                    <CInput label="Description" v-model="new_divider.description" />
                    <div class="invalid-feedback d-block" v-if="hasError('description')">
                        {{ getError("description") }}
                    </div>
                </CCol>
            </CRow>
            <CRow>
                <CCol lg="12">
                    <CInput label="order" v-model="order" />
                    <div class="invalid-feedback d-block" v-if="hasError('order')">
                        {{ getError("order") }}
                    </div>
                </CCol>
            </CRow>
            <div class="d-flex mt-3 align-items-center justify-content-center">
                <CButton color="secondary" class="mx-auto text-uppercase" @click="showModal = false">Cancel</CButton>
                <CButton color="primary" class="mx-auto text-uppercase btn_yes" @click="save()"> {{ id ? 'Save' : 'Add' }}</CButton>
            </div>
        </CCardBody>
        <template #footer-wrapper>
            <div class="d-flex my-0 align-items-center justify-content-center">
            </div>
        </template>
    </CModal>

</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

    export default {
        name: "DividerModal",
        mixins: [hasApiValidation],
        data() {
            return {
                loadinEdit : false,
                showModal: false,
                title: '',
                id: null,
                order: 1,
                idCollection: null,
                collectionItemId: null,
                new_divider: {
                    title: '',
                    description: ''
                },
                new_collection_item: {
                    item_session_id: null,
                    item_divider_id: null,
                    collection_id: null,
                    order: null,
                },
                urlAddDivider: '/collection-item-dividers',
                urlAddCollectionItems: '/collection-items'
            }
        },
        mounted() {
            
            
        },
        methods: {
            fetchData(id){
                this.loadinEdit = true;
                this.$http.get(`${this.urlAddDivider}/${id}`).then(({data})=>{
                    this.new_divider.title = data.title;
                    this.new_divider.description = data.description;
                    this.loadinEdit = false;
                });
            },
            closeModal() {
                this.clearData();
                this.showModal = false;
            },
            open(title, id, idCollection, order, collectionItemId) {
                this.showModal = true;
                this.title = title;
                this.id = id;
                this.order = order;
                this.idCollection = idCollection;
                this.collectionItemId = collectionItemId;
                if (this.id) {
                    this.fetchData(id);
                }
            },
            save(){
                if (this.id){
                    this.update(this.id);
                } else {
                    this.new();
                    
                }
            },
            update(id){
                this.$http.put(`${this.urlAddDivider}/${id}`,this.new_divider).then(({data})=>{
                    this.new_collection_item.item_divider_id = data.id;
                    this.new_collection_item.collection_id = this.idCollection;
                    this.new_collection_item.order = this.order;
                    this.$http.put(`${this.urlAddCollectionItems}/${this.collectionItemId}`, this.new_collection_item)
                    .then(({response})=>{

                        this.clearData();

                        this.$noty.success("Update!!");
                        this.showModal = false;
                        this.$emit('operation-success', { success: true });
                    })
                    
                });
            },
            clearData() {
                this.new_divider.title = '';
                this.new_divider.description = '';
            },
            new(){
                this.$http.post(`${this.urlAddDivider}`, this.new_divider)
                    .then(({data})=>{
                        this.new_collection_item.item_divider_id = data.id;
                        this.new_collection_item.collection_id = this.idCollection;
                        this.new_collection_item.order = this.order;
                        this.$http.post(`${this.urlAddCollectionItems}`, this.new_collection_item)
                        .then(({datac})=>{
                            this.$noty.success("Created");
                            this.showModal = false;
                            this.$emit('operation-success', { success: true });
                        })
                        .catch(({response})=>{
                            this.$noty.error(response.data.message);
                            this.setErrors(response.data.errors);
                        });
                    })
                    .catch(({response})=>{
                        this.$noty.error(response.data.message);
                        this.setErrors(response.data.errors);
                    });
            }
        }
    }
</script>

<style scoped>

</style>