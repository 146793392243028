<template>
  <CCard>
    <CCardHeader class="d-flex ">
      <h2 class="mb-0">Edit Profile</h2>
    </CCardHeader>
    <CCardBody>
      <form @submit.prevent="submit" autocomplete="off" v-if="!this.user_loading">
        <CInput
            label="First Name"
            v-model="new_admin.first_name"
            autocomplete="first_name"
            :addInputClasses="{'is-invalid': hasError('first_name')}"
            :invalid-feedback="getError('first_name')"
        ></CInput>
        <CInput
            label="Last Name"
            v-model="new_admin.last_name"
            autocomplete="last_name"
            :addInputClasses="{'is-invalid': hasError('last_name')}"
            :invalid-feedback="getError('last_name')"
        ></CInput>
        <CInput
            label="Email"
            v-model="new_admin.email"
            autocomplete="email"
            :addInputClasses="{'is-invalid': hasError('email')}"
            :invalid-feedback="getError('email')"
        ></CInput>
        <CInput
            label="Password"
            type="password"
            v-model="new_admin.password"
            autocomplete="new-password"
            :addInputClasses="{'is-invalid': hasError('password')}"
            :invalid-feedback="getError('password')"
        ></CInput>
        <CInput
            label="Confirm Password"
            type="password"
            autocomplete="new-password"
            v-model="new_admin.password_confirmation"
            :addInputClasses="{'is-invalid': hasError('password_confirmation')}"
            :invalid-feedback="getError('password_confirmation')"
        ></CInput>
        <CButton type="submit" color="primary">Save</CButton>
      </form>
      <div v-else class="mb-3">
        <CSpinner
            style="width:2rem;height:2rem;"
            color="primary"
            grow
        />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: "EditAdminProfile",
  mixins: [hasApiValidation],
  props: ['id'],
  data() {
    return {
      roles: [],
      new_admin: {
        last_name: '',
        first_name: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
      user_loading: false,
    }
  },
  mounted() {
    this.user_loading = true;
    this.fetchUser();
  },
  methods: {
    fetchUser() {
      this.$http.get('/auth/me/').then(({data}) => {
        this.new_admin.first_name = data.data.first_name;
        this.new_admin.last_name = data.data.last_name;
        this.new_admin.email = data.data.email;
      }).finally(() => {
        this.user_loading = false;
      })
    },
    submit() {
      this.update();
    },
    update() {
      this.setErrors({});
      this.$http.put('/me/', this.new_admin).then((data) => {
          this.$noty.success('Updated');
          this.new_admin.password = this.new_admin.password_confirmation = '';
      }).catch(({response}) => {
        this.$noty.error(response.data.message);
        this.setErrors(response.data.errors);
      })
    }
  },
  computed: {
  }
}
</script>

<style scoped>

</style>