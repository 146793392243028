<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-between">
      <h2 class="text-uppercase font-weight-bold mb-0">Enterprise Builds</h2>
      <CButton @click="newBuild" color="primary">Upload New Version</CButton>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :items="data"
        :fields="fields"
        :loading="loading"
        pagination
        class="mb-0"
      >
        <template #is_current_version="data">
          <td>
            <span
              v-if="data.item.is_current_version === 1"
              class="badge badge-published"
            >
              Published
            </span>
          </td>
        </template>
        <template #creation_date="data">
          <td>
            {{ formatDate(data.item.creation_date) }}
          </td>
        </template>
        <template #published_date="data">
          <td>
            {{ data.item.published_date ? formatDate(data.item.published_date) : '' }}
          </td>
        </template>
        <template #actions="data">
          <td class="text-right">
            <div class="button-group">
              <CButton
                v-if="data.item.is_current_version === 0"
                @click="deleteBuild(data.item.id)"
                color="danger"
                class="action-button"
              >
                Delete
              </CButton>

              <CButton
                v-if="shouldShowPublishButton(data.item)"
                @click="publishBuild(data.item.id)"
                color="success"
                class="action-button"
              >
                Publish
              </CButton>

              <CButton
                v-if="data.item.temporary_download_link"
                :href="data.item.temporary_download_link"
                target="_blank"
                color="primary"
                class="action-button" 
                download
              >
                Download
              </CButton>
            </div>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <upload-build-modal ref="upload_modal" @saved="fetchData"></upload-build-modal>
  </CCard>
</template>

<script>
import UploadBuildModal from './UploadBuildModal.vue';

export default {
  name: "EnterpriseBuilds",
  components: {
    UploadBuildModal,
  },
  data() {
    return {
      data: [],
      loading: true,
      fields: [
        { key: 'name', label: 'Name' },
        { key: 'version', label: 'Version' },
        { key: 'build', label: 'Build' },
        { key: 'package', label: 'Package' },
        { key: 'is_current_version', label: 'Published' },
        { key: 'creation_date', label: 'Creation Date' },
        { key: 'published_date', label: 'Published Date' },
        { key: 'actions', label: 'Actions', class: 'text-right' },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        const response = await this.$http.get('/app-versions');
        this.data = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.loading = false;
      }
    },
    formatDate(dateString) {
      const options = { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit', 
        hour12: true 
      };
      return new Date(dateString).toLocaleString('en-US', options);
    },
    newBuild() {
      console.log('Open upload modal');
      this.$refs.upload_modal.open();
    },
    async deleteBuild(buildId) {
      if (confirm('Are you sure you want to delete this build?')) {
        try {
          await this.$http.delete(`/app-versions/${buildId}`);
          this.fetchData();
        } catch (error) {
          console.error('Error deleting build:', error);
        }
      }
    },
    async publishBuild(buildId) {
      try {
        await this.$http.post(`/app-versions/${buildId}/publish`);
        this.fetchData();
      } catch (error) {
        console.error('Error publishing build:', error);
      }
    },
    shouldShowPublishButton(buildItem) {
      const currentVersion = this.data.find(item => item.is_current_version === 1);
      const isHigherVersion = currentVersion ? buildItem.build > currentVersion.build : true;
      return buildItem.is_current_version === 0 && isHigherVersion;
    },
  },
};
</script>

<style scoped>
.badge-published {
  background-color: grey;
  color: white;
  padding: 0.25em 0.5em;
  border-radius: 0.25em;
  font-size: 0.875em;
}

.button-group {
  display: flex;
  justify-content: flex-end;
}

.action-button {
  height: 40px; 
  margin-left: 0.5rem; 
  display: flex; 
  align-items: center; 
  justify-content: center; 
}
</style>
