<template>
  <form class="segment_form">
    <div class="row">
      <div class="col-md-5 col-12 d-flex align-baseline justify-content-between">
        <CInput v-model="new_segment.name" label="Name" class="flex-grow-1"
                :invalidFeedback="getError('name')"
        ></CInput>
        <div class="segment_color ms-2" :style="{'background-color':new_segment.color}"
             style="margin-top: 35px"></div>
      </div>
      <div class="col-2 ml-auto d-flex align-items-center justify-content-end">
        <span class="mr-3 segment_icons" @click="saveSegment()"><i class="fa fa-check "></i></span>
        <span class="segment_icons" @click="deleteSegment()"><i class="fa fa-trash "></i></span>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-md-6 col-lg-4 col-xl-3 mb-3">
        <c-form-group :invalidFeedback="getError('start')">
          <template #label><label class="fw-medium">Segment start</label></template>
          <template #input>
            <the-mask v-model="start_segment"
                      label="Segment start"
                      placeholder="00:00"
                      class="form-control"
                      :masked="true"
                      mask="##:##"
            ></the-mask>
          </template>
        </c-form-group>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3 mb-3">
        <c-form-group :invalidFeedback="getError('end')">
          <template #label><label class="fw-medium">Segment end</label></template>
          <template #input>
            <the-mask v-model="end_segment"
                      label="Segment end"
                      placeholder="00:00"
                      class="form-control"
                      :masked="true"
                      mask="##:##"
            ></the-mask>
          </template>
        </c-form-group>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3 mb-3">
        <c-form-group :invalidFeedback="getError('movement')">
          <template #label><label class="fw-medium">Movement</label></template>
          <template #input>
          <select v-model="new_segment.movement" class="form-control" id="movement" @change="updateCadenceRange()"
                  :class="{'is-invalid': hasError('movement')}"
          >
            <option :value="null" disabled>Select Movement</option>
            <option v-for="movement in movements" :key="movement.id" :value="movement.id">{{ movement.name }}</option>
          </select>
          </template>
        </c-form-group>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3 mb-3">
        <label class="fw-medium">Cadence Range</label>
        <div class="d-flex">
          <CInput v-model="new_segment.cadence_range.min" placeholder="Min" class="mr-2"
                  :addInputClasses="{'is-invalid': hasError('cadence_range.min')}" inline/>
          <CInput v-model="new_segment.cadence_range.max" placeholder="Max"
                  :addInputClasses="{'is-invalid': hasError('cadence_range.max')}" inline/>
        </div>
        <div class="invalid-feedback d-block"
             v-if="hasError('cadence_range.max') || hasError('cadence_range.min')">
          <span class="d-block" v-if="hasError('cadence_range.max')"> {{ getError('cadence_range.max') }} </span>
          <span class="d-block" v-if="hasError('cadence_range.min')"> {{ getError('cadence_range.min') }} </span>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3 mb-3">
        <c-form-group :invalidFeedback="getError('zone')">
          <template #label><label for="zone">Heart Rate Zone</label></template>
          <template #input>
            <select  v-model="new_segment.zone" class="form-control" id="zone" @change="updateZone()"
                     :class="{'is-invalid': hasError('zone')}"
            >
              <option :value="null" disabled>Select Training Zone</option>
              <option v-for="zone in zones" :key="zone.id" :value="zone.id">{{ zone.id }}</option>
            </select>
          </template>
        </c-form-group>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3 mb-3">
        <label class="fw-medium">% Max Heart Rate</label>
        <div class="d-flex">
          <CInput v-model="new_segment.max_heart_rate.min" placeholder="Min" class="mr-2" min="0" max="100"
                  :addInputClasses="{'is-invalid': hasError('max_heart_rate.min')}"
                  inline type="number"/>

          <CInput v-show="new_segment.max_heart_rate.max === 100" disabled
                  value="Max"
                  :addInputClasses="{'is-invalid': hasError('max_heart_rate.max')}"
                  :addWrapperClasses="['append_ico_custom']">
            <template #append>
              <span class="eye-btn" style="cursor: pointer;" @click="new_segment.max_heart_rate.max = 99"><i
                  class="far fa-times"></i></span>
            </template>
          </CInput>
          <CInput v-model.number="new_segment.max_heart_rate.max" placeholder="Max" min="0" max="100"
                  :addInputClasses="{'is-invalid': hasError('max_heart_rate.max')}"
                  inline v-show="new_segment.max_heart_rate.max !== 100" type="number"/>
        </div>
        <div class="invalid-feedback d-block"
             v-if="hasError('max_heart_rate.max') || hasError('max_heart_rate.min')">
          <span class="d-block" v-if="hasError('max_heart_rate.max')"> {{ getError('max_heart_rate.max') }} </span>
          <span class="d-block" v-if="hasError('max_heart_rate.min')"> {{ getError('max_heart_rate.min') }} </span>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3 mb-3">
        <label class="fw-medium">FTP / PST %</label>
        <div class="d-flex">
          <CInput v-show="new_segment.pst.min === -1" disabled
                  class="mr-2" value="Below"
                  :addInputClasses="{'is-invalid': hasError('pst.min')}"
                  :addWrapperClasses="['append_ico_custom']">
            <template #append>
              <span class="eye-btn" style="cursor: pointer;" @click="new_segment.pst.min = 0"><i
                  class="far fa-times"></i></span>
            </template>
          </CInput>
          <CInput v-model.number="new_segment.pst.min" placeholder="Min" class="mr-2" type="number"

                  :addInputClasses="{'is-invalid': hasError('pst.min')}" inline v-show="new_segment.pst.min !== -1"/>
          <CInput v-show="new_segment.pst.max === -1" disabled
                  value="Max" :addWrapperClasses="['append_ico_custom']">
            <template #append>
              <span class="eye-btn" style="cursor: pointer;" @click="new_segment.pst.max = 0"><i
                  class="far fa-times"></i></span>
            </template>
          </CInput>
          <CInput v-model.number="new_segment.pst.max" placeholder="Max" type="number" min="-1"
                  :addInputClasses="{'is-invalid': hasError('pst.max')}" inline v-show="new_segment.pst.max !== -1"/>
        </div>
        <div class="invalid-feedback d-block"
             v-if="hasError('pst.max') || hasError('pst.min')">
          <span class="d-block" v-if="hasError('pst.max')"> {{ getError('pst.max') }} </span>
          <span class="d-block" v-if="hasError('pst.min')"> {{ getError('pst.min') }} </span>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 col-xl-3 mb-3">
        <CInput v-model="new_segment.rpe" label="RPE" :addInputClasses="{'is-invalid': hasError('rpe')}" :invalidFeedback="getError('rpe')"/>
      </div>
    </div>
  </form>
</template>
<script>
import hasApiValidation from "@/mixins/hasApiValidation";
import {mask} from 'vue-the-mask'
import {TheMask} from 'vue-the-mask'

export default {
  name: 'AudioSegmentForm',
  components: {TheMask},
  mixins: [hasApiValidation],
  directives: {mask},
  props: {
    id: {
      required: true
    },
    max: {
      type: Number,
      default: 0,
    },
    segment: {
      type: Object,
    },
    movements: {
      required: true,
      type: Array,
    },
    zones: {
      required: true,
      type: Array,
    },
  },
  computed: {
    start_segment: {
      get: function () {
        if (this.new_segment.start) {
          let minutes = Math.floor((this.new_segment.start / 60));
          let seconds = this.new_segment.start - (minutes * 60);

          if (minutes < 10) {
            minutes = "0" + minutes;
          }
          if (seconds < 10) {
            seconds = "0" + seconds;
          }
          return minutes + ':' + seconds;
        } else {
          return '00:00'
        }
      },
      set: function (val) {
        this.parseValue(val, 'start')
      },
    },
    end_segment: {
      get: function () {
        if (this.new_segment.end) {
          let minutes = Math.floor((this.new_segment.end / 60));
          let seconds = this.new_segment.end - (minutes * 60);

          if (minutes < 10) {
            minutes = "0" + minutes;
          }
          if (seconds < 10) {
            seconds = "0" + seconds;
          }
          return minutes + ':' + seconds;
        } else {
          return '00:00'
        }

      },
      set: function (val) {
        this.parseValue(val, 'end')
        // this.new_segment.start = val;
      },
    }
  },
  data() {
    return {
      test: '',
      new_segment: {
        start: 0,
        end: 0,
        color: 'rgba(0,0,0,0.2)',
        drag: false,
        resize: false,
        id: 'new_reg',
        name: 'New region',
        movement: null,
        cadence_range: {
          min: null,
          max: null
        },
        zone: null,
        max_heart_rate: {
          min: null,
          max: null
        },
        pst: {
          min: null,
          max: null
        },
        rpe: null,
      },
    }
  },
  mounted() {
    if (this.segment) {
      this.new_segment = Object.assign({}, this.segment);
    }
  },
  methods: {
    parseValue(event, time) {
      let a = event.split(':'); // split it at the colons
      let seconds = (+parseInt(a[0])) * 60 + (+parseInt(a[1]));
      if (seconds > this.max) {
        this.new_segment[time] = Math.floor(this.max);
      } else {
        this.new_segment[time] = seconds;
      }
    },
    updateValue(start, time) {
      this.new_segment[time] = start;
    },
    updateCadenceRange() {
      let mov = this.movements.find((item) => item.id === this.new_segment.movement);
      this.new_segment.cadence_range = {
        min: mov.cadence_min,
        max: mov.cadence_max,
      }
    },
    updateZone() {
      let zone = this.zones.find((item) => item.id === this.new_segment.zone);
      this.new_segment.max_heart_rate = {
        min: zone.mhr.min,
        max: zone.mhr.max,
      }
      this.new_segment.pst = {
        min: zone.pst.min,
        max: zone.pst.max,
      }
      this.new_segment.color = zone.color
      this.new_segment.rpe = zone.rpe
    },
    deleteSegment() {
      if (!this.segment) {
        this.new_segment = {
          start: 0,
          end: 0,
          color: 'rgba(0,0,0,0.2)',
          drag: false,
          resize: false,
          id: 'new_reg',
          name: 'New region',
          movement: null,
          cadence_range: {
            min: null,
            max: null
          },
          zone: null,
          max_heart_rate: {
            min: null,
            max: null
          },
          pst: {
            min: null,
            max: null
          },
          rpe: null,
        }
      }
      this.$emit('delete', this.new_segment);
    },
    saveSegment() {
      if (this.segment) {
        this.$emit('errors', {errors: {}, segment: this.segment});
      } else {
        this.setErrors({});
      }
      this.$http.put(`/sessions/${this.id}/segments/validate`, this.new_segment).then(() => {
        this.new_segment.errors = null;
        if (!this.segment) {
          this.$emit('saved', this.new_segment);
        } else {
          this.$emit('updated', this.new_segment);
        }
      }).catch(({response}) => {
        this.new_segment.errors = null;
        if (this.segment) {
          this.$emit('errors', {errors: response.data.errors, segment: this.segment});
        } else {
          this.setErrors(response.data.errors);
        }
      })
    },
    hasError(key) {
      return (this.segment && this.segment.errors && !!this.segment.errors[key]) || (this.errors && !!this.errors[key]);
    },
    getErrors(key) {
      return (this.segment && this.segment.errors && this.segment.errors[key]) ?
          (typeof this.segment.errors[key] === 'string') ? [this.segment.errors[key]] : this.segment.errors[key]
          : (this.errors && this.errors[key]) ?
              (typeof this.errors[key] === 'string') ? [this.errors[key]] : this.errors[key]
              : [];
    },
    getError(key) {
      return (this.segment && this.segment.errors && this.segment.errors[key]) ?
          (typeof this.segment.errors[key] === 'string') ? this.segment.errors[key] : this.segment.errors[key][0]
          : (this.errors && this.errors[key]) ?
              (typeof this.errors[key] === 'string') ? this.errors[key] : this.errors[key][0]
              : '';
    },
  },
  watch: {
    // 'segment.errors': {
    //   handler: function (val) {
    //     this.errors = val;
    //   }
    // },
    'segment.end': {
      handler: function (val) {
        this.new_segment.end = val;
      }
    },
    'segment.start': {
      handler: function (val) {
        this.new_segment.start = val;
      }
    },
    'new_segment.end': {
      handler: function (val) {
        if (val > this.max) {
          this.new_segment.end = Math.floor(this.max);
        }
      }
    },
    'new_segment.start': {
      handler: function (val) {
        if (val > this.max) {
          this.new_segment.start = Math.floor(this.max);
        }
      }
    },
  }
}
</script>
<style scoped>
.d-flex > .form-group {
  flex-grow: 1;
}
div >>> .append_ico_custom .input-group-append {
  position: absolute;
  right: 0.5rem;
  top: calc(50% - 0.5rem);
}
div >>> .append_ico_custom{
  position:relative;
}
div >>> .form-group {
  margin-bottom: 0;
}

.segment_icons {
  width: 26px;
  height: 26px;
  background-color: var(--primary);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


</style>