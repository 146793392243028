<template>
  <div>
    <CMultiSelectRender
        :items="optionsStructure"
        v-on:select-item="onSelectItem"
        type="select"
        :name="name"
        :form="form"
    />
    <div
        :class="multiSelectClass"
        v-on:click.self="ulShow"
        v-c-clickaway="visible ? clickAway : null"
    >
      <span
          class="c-multi-select-selection"
          v-if="selectionType === 'counter' && selectedItems.length >= 0 && !inline"
          v-on:click="ulShow"
      >
        <input
            ref="inputRef"
            v-if="search && !inline"
            class="c-multi-select-search"
            :placeholder="inputPlaceholder"
            type="text"
            v-model="searchInput"
            v-on:keyup="onSearchChange($event)"
            @focus="ulShow"
            @keydown.tab="handleTabPress($event)"
        />
      </span>
      <span
          class="c-multi-select-selection"
          v-if="selection && selectionType === 'tags'"
          v-on:click="ulShow"
      >
        <span
            v-for="(item, index) in selectedItems"
            :key="index"
            class="c-multi-select-tag"
        >
          {{ item.text }}
          <span
              class="c-multi-select-tag-delete close"
              aria-label="Close"
              @click="removeSelected(item.value)"
          >
            <span aria-hidden="true">×</span>
          </span>
        </span>
        <input
            ref="inputRef"
            v-if="search && !inline"
            class="c-multi-select-search"
            :placeholder="inputPlaceholder"
            type="text"
            v-model="searchInput"
            v-on:keyup="onSearchChange($event)"
            @focus="ulShow"
            @keydown.tab="handleTabPress($event)"
        />
      </span>
      <span
          class="c-multi-select-selection"
          v-if="selection && selectionType === 'text'"
          v-on:click="ulShow"
      >
        <span
            v-for="(item, index) in selectedItems"
            :key="index"
        >
          {{ index !== 0 ? ',' : '' }} {{ item.text }}
        </span>
          <input
              ref="inputRef"
              v-if="search && !inline"
              class="c-multi-select-search"
              :placeholder="inputPlaceholder"
              type="text"
              v-model="searchInput"
              v-on:keyup="onSearchChange($event)"
              @focus="ulShow"
              @keydown.tab="handleTabPress($event)"
          />
      </span>
      <input
          ref="inputRef"
          v-if="search && inline"
          class="c-multi-select-search"
          :placeholder="inputPlaceholder"
          type="text"
          v-model="searchInput"
          v-on:keyup="onSearchChange($event)"
          @focus="ulShow"
          @keydown.tab="handleTabPress($event)"
      />
      <span v-if="visible" class="toggle_button" @click="ulClose()"></span>
      <CMultiSelectRender
          v-if="optionsNotEmpty"
          :items="optionsStructure"
          v-on:select-item="onSelectItem"
          v-on:close-select="ulClose"
          :ulClass="ulClass"
          type="div"
      />
      <div
          v-else
          class="c-multi-select-options"
      >
        <div class="c-multi-select-options-empty">
          {{ optionsEmptyPlaceholder }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import CMultiSelected from '@coreui/vue-pro/src/components/multi-select/CMultiSelected.vue'
import CMultiSelectRender from '@coreui/vue-pro/src/components/multi-select/CMultiSelectRender.vue'
import {CClickaway} from '@coreui/vue-pro/src/directives'

const props = {
  name: String,
  form: String,
  selected: Array, //initially selected values
  search: Boolean, //search value
  select: Boolean, //use select tag
  options: Array,
  inline: Boolean,
  multiple: {
    type: Boolean,
    default: true,
  },
  optionsEmptyPlaceholder: {
    type: String,
    default: 'no items'
  },
  searchPlaceholder: {
    type: String,
    default: 'Select...',
  },
  selection: {
    type: Boolean,
    default: true,
  },
  selectionType: {
    type: String,
    default: 'counter',
  },
  selectionTypeCounterText: {
    type: String,
    default: 'item(s) selected',
  }
}

export default {
  name: 'MultiSelect',
  props,
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CMultiSelected,
    CMultiSelectRender,
  },
  directives: {
    CClickaway
  },
  data: function () {
    return {
      ulClass: 'hide',
      classes: 'c-select ',
      classesSelect: 'custom-select custom-select-sm',
      selectedItems: [],
      singleSelected: '',
      optionsStructure: [],
      searchInput: '',
      visible: false,
      multi: true,
      placeholder: '',
      placeholderInput: '',
      singleChar: false,
      optionsNotEmpty: true,
      lastSelected: {}
    }
  },
  computed: {
    multiSelectClass() {
      return {
        'c-multi-select': true,
        'c-multi-select-inline': this.inline,
        'c-multi-select-multiple': this.multiple,
        'c-multi-select-selection-tags': this.selectionType === 'tags',
        'c-show': this.ulClass === '' && !this.inline
      }
    },
    inputPlaceholder() {
      let placeholder = null
      if (this.searchInput.length === 0 && this.multi) {
        placeholder = this.searchPlaceholder
      }
      if (this.selectionType === 'counter' && this.selectedItems.length > 0) {
        placeholder = `${this.selectedItems.length} ${this.selectionTypeCounterText}`
      }
      return placeholder
    }
  },
  methods: {
    handleTabPress: function (e) {
      if (e.shiftKey) {
        this.ulClose()
      }
    },
    onChangeSelectInner: function (arr1, selected) {
      if (typeof arr1.options === 'object') {
        for (let i = 0; i < arr1.options.length; i++) {
          this.onChangeSelectInner(arr1.options[i], selected)
        }
      }
      if (typeof arr1.text != 'undefined') {
        if (typeof arr1.selected == 'undefined') {
          arr1['selected'] = false
        }
        arr1.selected = false
        for (let i = 0; i < selected.length; i++) {
          if (arr1.value === selected[i].value) {
            arr1.selected = true
            break
          }
        }
      }
    },
    removeSelected: function (toRemove) {
      this.selectedItems = this.selectedItems.filter(function (el) {
        return el.value !== toRemove
      })
      this.onChangeSelectInner(this.optionsStructure[0], this.selectedItems)
      this.emitSelected()
      this.optionsNotEmpty = false
      this.$nextTick(function(){
        this.optionsNotEmpty = true
      })
    },
    onSelectItem: function (item, el) {
      let selected = this.selectedItems.filter(function (el) {
        return el.value === item.value
      }).length
      this.lastSelected = el
      if (selected === 0) {
        if (this.multi === false) {
          this.selectedItems = []
        }
        this.selectedItems.push({
          text: item.text,
          value: item.value
        })
        this.emitSelected()
        this.onChangeSelectInner(this.optionsStructure[0], this.selectedItems)
        this.optionsNotEmpty = false
        this.$nextTick(function(){
          this.optionsNotEmpty = true
        })
      } else {
        this.removeSelected(item.value)
      }
      // clear search input, show all options
      this.searchInput = ''
      this.singleChar = true
      this.$refs?.inputRef?.focus()
      const notEmpty = this.onSearchChangeInner(this.optionsStructure[0])
      this.optionsNotEmpty = !notEmpty
      this.$nextTick(function(){
        this.optionsNotEmpty = notEmpty
      })
    },
    emitSelected: function () {
      let selected = []
      for (let i = 0; i < this.selectedItems.length; i++) {
        selected.push(this.selectedItems[i].value)
      }
      this.$emit('update', selected)
    },
    clickAway: function (e) {
      if (e.target !== this.lastSelected) {
        this.ulClose()
      }
    },
    ulClick: function () {
      if (this.ulClass === 'hide') {
        this.ulClass = ''
      } else {
        this.ulClass = 'hide'
      }
    },
    ulShow: function () {
      this.ulClass = ''
      this.visible = true
      this.$refs?.inputRef?.focus();
    },
    ulClose: function () {
      if (this.inline) {
        return
      }
      this.ulClass = 'hide'
      this.visible = false
    },
    onInputClick: function () {
      this.ulClick()
      this.visible = true
    },
    onSearchChangeInner: function (arr1) {
      let aEmpty = false
      let result = false
      if (typeof arr1.options === 'object') {
        for (let i = 0; i < arr1.options.length; i++) {
          aEmpty = this.onSearchChangeInner(arr1.options[i])
          if (aEmpty === true) {
            result = true
          }
        }
      }
      if (typeof arr1.text != 'undefined') {
        if (typeof arr1.visible == 'undefined') {
          arr1['visible'] = true
        }
        if (arr1.text.toLowerCase().includes(this.searchInput.toLowerCase()) || this.searchInput === '') {
          arr1.visible = true
          result = true
        } else {
          arr1.visible = false
        }
      }
      return result
    },
    onSearchChange: function (e) {
      let notEmpty = false
      if (e.key === 'Backspace' && this.searchInput === '' && this.selectedItems.length > 0 && this.singleChar) {
        this.selectedItems.pop()
        this.onChangeSelectInner(this.optionsStructure[0], this.selectedItems)
        this.emitSelected()
      }
      this.singleChar = this.searchInput.length === 0;
      notEmpty = this.onSearchChangeInner(this.optionsStructure[0])
      this.optionsNotEmpty = !notEmpty
      this.$nextTick(function(){
        this.optionsNotEmpty = notEmpty
      })
      this.ulShow();
    },
    newSlotStructure: function (slot, deep, selected) {
      let value = ''
      let text = ''
      for (let i = 0; i < slot.length; i++) {
        if (typeof slot[i].tag == 'undefined') {
          continue
        }
        if (slot[i].tag === 'optgroup') {
          if (typeof slot[i].data == 'undefined') {
            text = ''
          } else {
            text = slot[i].data.attrs.label
          }
          //this.putInRightPlace(text, text, 'c-label', deep, selected)
          this.putInRightPlace(text, text, 'ul', deep, selected)
          this.newSlotStructure(
              slot[i].children,
              deep + 1,
              selected
          )
        } else {
          if (typeof slot[i].children == 'undefined') {
            text = ''
          } else {
            text = slot[i].children[0].text
          }
          if (typeof slot[i].data == 'undefined' || typeof slot[i].data.attrs == 'undefined' || typeof slot[i].data.attrs.value == 'undefined') {
            value = text
          } else {
            value = slot[i].data.attrs.value
            if (text.length === 0) {
              text = value
            }
          }
          if (typeof text != 'undefined' || typeof value != 'undefined') {
            this.putInRightPlace(text, value, 'li', deep, selected)
          }
        }
      }
    },

    putInRightPlace: function (text, value, type, deep, selectedArr) {
      let arr1 = this.optionsStructure
      let z = 0
      let selected = false
      while (typeof arr1 == 'object') {
        if (z === deep) {
          if (type === 'li') {
            for (let i = 0; i < selectedArr.length; i++) {
              if (selectedArr[i] === value) {
                selected = true
              }
            }
          }
          if (type === 'ul') {
            arr1[arr1.length - 1].options.push({
              label: text,
              options: [],
              visible: true,
            })
          } else if (selected) {
            arr1[arr1.length - 1].options.push({
              value: value,
              text: text,
              visible: true,
              selected: true,
            })
          } else {
            arr1[arr1.length - 1].options.push({
              value: value,
              text: text,
              visible: true,
            })
          }
          return true;
        } else {
          arr1 = arr1[arr1.length - 1].options
          z++
        }
      }
    },
    initialSelectedInner: function (arr1, value) {
      let a = []
      let result = []
      if (typeof arr1.options !== 'undefined') {
        for (let i = 0; i < arr1.options.length; i++) {
          a = this.initialSelectedInner(arr1.options[i], value)
          if (typeof a == 'object') {
            result = result.concat(a)
          } else {
            result.push({
              value: value,
              text: a
            })
          }
        }
      }
      if (typeof arr1.text !== 'undefined') {
        if (arr1.value === value) {
          result = arr1.text
        }
      }
      return result
    },
    initialSelected: function (selected) {
      let result = ''
      if (typeof selected == 'undefined') {
        return 0
      }
      for (let i = 0; i < selected.length; i++) {
        result = this.initialSelectedInner(this.optionsStructure[0], selected[i])
        if (typeof result == 'string') {
          this.selectedItems.push({
            value: selected[i],
            text: result
          })
        } else {
          for (let j = 0; j < result.length; j++) {
            this.selectedItems.push(result[j])
          }
        }
      }
      this.onChangeSelectInner(this.optionsStructure[0], this.selectedItems)
    }
  },
  mounted() {
    let selected = []
    this.optionsStructure = [{options: []}]
    if (this.inline) {
      this.ulShow()
    }
    this.multi = !!this.multiple
    if (this.options) {  //data from options
      this.optionsStructure[0].options = JSON.parse(JSON.stringify(this.options))
    } else if (typeof this.$slots.default !== 'undefined') {  //data from slot
      if (typeof this.selected !== 'undefined') {
        selected = this.selected
      }
      this.newSlotStructure(this.$slots.default, 0, selected)
    }
    if (typeof this.selected !== 'undefined') {
      this.initialSelected(this.selected)
    }
    if (this.optionsStructure[0].options.length === 0) {
      this.optionsNotEmpty = false
    }
  }
}


</script>

<style scoped>

/*
.c-multi-selected {
  background-color: yellow;
}

input {
  background-color: yellow;
}
 */

.c-multi-select-selection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.c-multi-select-selection > * {
  flex: 0 0 auto;
}
.c-multi-select:not(.c-multi-select-selection-tags) .c-multi-select-selection input.c-multi-select-search:not(:first-child) {
  margin-left: .75rem;
}
.c-multi-select-selection input.c-multi-select-search {
  flex-grow: 1;
  flex-basis: 2em;
  min-width: 2em;
  max-width: 100%;
}
.c-multi-select-inline .c-multi-select-selection:empty {
  margin-bottom: 0;
}
.toggle_button{
  position: absolute;
  display: block;
  width: 25px;
  background: transparent;
  cursor: pointer;
  height: 100%;
  right: 0;
  top:0;
}
</style>
