<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-end">
      <h2 class="text-uppercase font-weight-bold mb-0">List of Roles</h2>
      <CButton @click="createRole" class="ml-auto mr-3" color="primary"  v-if="$auth.check('roles_crud')">Add New</CButton>
      <CInput v-model="filters.search" @update:value="fetchData" class="mb-0" placeholder="Search"></CInput>
    </CCardHeader>
    <CCardBody>
      <CDataTable
          :items="data"
          :fields="c_fields"
          :pagination="false"
          :sorter="{external: true}"
          :loading="loading"
          class="mb-0"
          @update:sorter-value="sortingChange"
      >
        <template #actions="data">
          <td>
            <div class="d-flex">

              <CButton size="sm" color="warning" class="text-white mr-2" @click="editRole(data.item.id)" >Edit</CButton>
              <CButton size="sm" color="danger" class="text-white" @click="deleteRole(data.item.id)">Delete</CButton>
            </div>
          </td>
        </template>
      </CDataTable>
      <pagination :current-page="options.page" @paginate="pageChange" :last-page="options.last_page" class="mr-3"/>
    </CCardBody>
    <delete-confirm-modal ref="delete_modal" @confirmed="destroyRole"></delete-confirm-modal>
  </CCard>
</template>

<script>
import externalTable from "@/mixins/externalTable";
import DeleteConfirmModal from "@/views/admins/DeleteConfirmModal";

export default {
  name: "RoleList",
  components: {DeleteConfirmModal},
  mixins: [externalTable],

  data() {
    return {
      filters: {
        search: '',
      },
      options: {
        order: 'asc'
      },
      fields: [
        {
          key: 'name', label: 'Role',
        },
      ],
      url: '/roles'
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    createRole() {
      this.$router.push({name: 'New Role'});
    },
    editRole(id) {
      this.$router.push({name: 'Edit Role', params: {id: id}});
    },
    deleteRole(id) {
      this.$refs.delete_modal.open(id);
    },
    destroyRole(id) {
      this.$http.delete('/roles/' + id).then(() => {
        this.$noty.success('Deleted!');
        this.fetchData()
      })
    },
  },
  computed:{
    c_fields(){
      let fields = this.fields.slice(0);
      if(this.$auth.check('roles_crud')){
        fields.push(
            {
              key: 'actions', label: 'Actions',
            });
      }
      return fields
    }
  }
}
</script>

<style scoped>

</style>