<template>
   <div>
    <nav class="nav nav-pills mb-3">
    <router-link :to="{name:'CollectionsList', params: {currentPage: 1}}" class="mr-3 nav-link">Collections</router-link>
    <router-link :to="{name:'Categories', params: {currentPage: 1}}" class="mr-3 nav-link">Categories</router-link>    
    </nav>
    <router-view></router-view>
  </div>
</template>


<script>
export default{
    name: "Collections"
};
</script>