<template>
  <CCard>
    <CCardHeader
      class="d-flex justify-content-between align-items-center card-header"
    >
      <h2 v-if="!id" class="mb-0">New Instructor</h2>
      <h2 v-else class="mb-0">Edit Instructor</h2>

      <CRow class="align-items-center card-header__row">
        <CSelect
          class="mb-0 mr-3"
          :value="current_language"
          :options="[
            { label: 'English', value: 'en' },
            { label: 'Spanish', value: 'es' },
            { label: 'German', value: 'de' },
            { label: 'Dutch', value: 'nl' }
          ]"
          @update:value="updateLanguage"
        >
        </CSelect>

        <CButton @click="resetPassword" color="primary">Reset Password</CButton>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <form
        @submit.prevent="submit"
        autocomplete="off"
        v-if="!this.id || !this.user_loading"
      >
        <CInput
          label="First Name"
          v-model="new_instructor.first_name"
          autocomplete="first-name"
          :addInputClasses="{ 'is-invalid': hasError('first_name') }"
          :invalid-feedback="getError('first_name')"
        ></CInput>
        <CInput
          label="Last Name"
          v-model="new_instructor.last_name"
          autocomplete="last-name"
          :addInputClasses="{ 'is-invalid': hasError('last_name') }"
          :invalid-feedback="getError('last_name')"
        ></CInput>
        <CInput
          label="Email"
          v-model="new_instructor.email"
          autocomplete="email"
          :disabled="!isEnglish"
          :addInputClasses="{ 'is-invalid': hasError('email') }"
          :invalid-feedback="getError('email')"
        ></CInput>
        <div class="mb-3">
          <label>Phone</label>
          <vue-tel-input
            v-model="new_instructor.phone"
            mode="international"
            :disabled="!isEnglish"
          ></vue-tel-input>
          <div class="invalid-feedback d-block" v-if="hasError('phone')">
            {{ getError("phone") }}
          </div>
        </div>
        <CTextarea
          label="Instructor Biography"
          v-model="new_instructor.description"
          autocomplete="description"
          :addInputClasses="{ 'is-invalid': hasError('description') }"
          :invalid-feedback="getError('description')"
        ></CTextarea>
        <CButton type="submit" color="primary" :disabled="!isEdited"
          >Save</CButton
        >
      </form>
      <div v-else class="mb-3">
        <CSpinner style="width:2rem;height:2rem;" color="primary" grow />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: "EditInstructor",
  mixins: [hasApiValidation],
  props: ["id"],
  beforeRouteLeave(to, from, next) {
    if (this.isEdited) {
      const answer = window.confirm(
        "You have unsaved changes. Are you sure you want to leave the page?"
      );
      if (answer) {
        return next();
      } else {
        return next(false);
      }
    } else {
      return next();
    }
  },
  data() {
    return {
      current_language: "en",
      roles: [],
      new_instructor: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        description: ""
      },
      loading_roles: true,
      user_loading: false,
      translations: {},
      originData: {}
    };
  },
  mounted() {
    this.user_loading = true;
    this.fetchUser(this.id);
  },
  computed: {
    isEnglish() {
      return this.current_language === "en";
    },
    isEdited() {
      const parser = data => JSON.stringify(data);
      return parser(this.originData) !== parser(this.new_instructor);
    }
  },
  methods: {
    setOriginData() {
      this.originData = JSON.parse(JSON.stringify(this.new_instructor));
    },
    setUserData(data) {
      if (this.isEnglish) {
        this.new_instructor.email = data.data.email;
        this.new_instructor.phone = data.data.phone;
      }

      this.new_instructor.id = data.data.id;
      this.new_instructor.first_name = data.data.first_name;
      this.new_instructor.last_name = data.data.last_name;
      this.new_instructor.description = data.data.description;
      this.translations = {
        ...data.data.translations,
        en: {
          first_name: data.data.first_name,
          last_name: data.data.last_name,
          description: data.data.description
        }
      };

      this.setOriginData();
    },
    fetchUser(id) {
      this.$http
        .get("/instructors/" + id)
        .then(({ data }) => {
          this.setUserData(data);
        })
        .finally(() => {
          this.user_loading = false;
        });
    },
    updateData(data) {
      this.new_instructor.roles = data;
    },
    updateLanguage(value) {
      const prevLanguage = this.current_language;

      if (this.isEdited) {
        const answer = window.confirm(
          "You have unsaved changes! Are you sure you want to leave the page?"
        );
        if (!answer) {
          this.current_language = null;
          this.$nextTick(() => {
            this.current_language = prevLanguage;
          });
          return;
        }
      }

      this.current_language = value;

      const currentLanguageData = this.translations[value] || {};

      this.new_instructor.first_name = currentLanguageData?.first_name || "";
      this.new_instructor.last_name = currentLanguageData?.last_name || "";
      this.new_instructor.description = currentLanguageData?.description || "";

      this.setOriginData();
    },
    logPhone(e) {
      console.log("loeg", e);
    },
    updatePhone(e) {
      console.log(e);
    },
    submit() {
      this.update();
    },
    resetPassword() {
      this.$http
        .put("/instructors/" + this.id + "/password")
        .then(data => {
          this.$noty.success("New password has been sent to the user's email");
        })
        .catch(({ response }) => {
          this.$noty.error(response.data.message);
        });
    },
    update() {
      this.setErrors({});
      let action;
      if (this.isEnglish) {
        action = this.$http.put("/instructors/" + this.id, this.new_instructor);
      } else {
        action = this.$http.post(
          `/instructors/${this.id}/update-or-create-translations`,
          {
            language: this.current_language,
            first_name: this.new_instructor.first_name,
            last_name: this.new_instructor.last_name,
            description: this.new_instructor.description
          }
        );
      }

      action
        .then(({ data }) => {
          this.setUserData(data);
          this.updateLanguage(this.current_language);
          this.$noty.success("Changes have been saved successfully");
        })
        .catch(({ response }) => {
          this.$noty.error(response.data.message);
          this.setErrors(response.data.errors);
        });
    }
  }
};
</script>

<style scoped></style>
