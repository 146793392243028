<template>
  <CCard>
    <CCardHeader class="d-flex ">
      <h2 v-if="!id" class="mb-0">New User</h2>
      <h2 v-else class="mb-0">Edit User</h2>
    </CCardHeader>
    <CCardBody>
      <form @submit.prevent="submit" autocomplete="off" v-if="!this.id || !this.user_loading">
        <CInput
            label="First Name"
            v-model="new_user.first_name"
            autocomplete="first-name"
            :addInputClasses="{'is-invalid': hasError('first_name')}"
            :invalid-feedback="getError('first_name')"
        ></CInput>
        <CInput
            label="Last Name"
            v-model="new_user.last_name"
            autocomplete="last-name"
            :addInputClasses="{'is-invalid': hasError('last_name')}"
            :invalid-feedback="getError('last_name')"
        ></CInput>
        <CInput
            label="Email"
            v-model="new_user.email"
            autocomplete="email"
            :addInputClasses="{'is-invalid': hasError('email')}"
            :invalid-feedback="getError('email')"
        ></CInput>
        <CButton type="submit" color="primary">Save</CButton>
      </form>
      <div v-else class="mb-3">
        <CSpinner
            style="width:2rem;height:2rem;"
            color="primary"
            grow
        />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: "EditInstructor",
  mixins: [hasApiValidation],
  props: ['id'],
  data() {
    return {
      roles: [],
      new_user: {
        first_name: '',
        last_name: '',
        email: '',
      },
      loading_roles: true,
      user_loading: false,
    }
  },
  mounted() {
    this.user_loading = true;
    this.fetchUser(this.id);
  },
  methods: {
    fetchUser(id) {
      this.$http.get('/users/' + id).then(({data}) => {
        this.new_user.id = data.data.id
        this.new_user.first_name = data.data.first_name;
        this.new_user.last_name = data.data.last_name;
        this.new_user.email = data.data.email;
      }).finally(() => {
        this.user_loading = false;
      })
    },
    updateData(data) {
      this.new_user.roles = data;
    },
    submit() {
      this.update();
    },
    update() {
      this.$http.put('/users/' + this.id, this.new_user).then((data) => {
        this.$noty.success('Updated');
        this.$router.push({name: 'Users'})
      }).catch(({response}) => {
        this.$noty.error(response.data.message);
        this.setErrors(response.data.errors);
      })
    }
  },
}
</script>

<style scoped>

</style>