<template>
  <CModal
      :show.sync="showModal"
      :size="'sm'"
      centered
      @update:show="closeModal"
  >
    <template #header-wrapper>
      <CCardHeader>
        <h4 class="font-weight-bold text-center ">
          Are you sure?
        </h4>
      </CCardHeader>
    </template>
    <CCardBody class="pt-0">
      <div class="d-flex mt-3 align-items-center justify-content-center">
        <CButton color="secondary" class="mx-auto text-uppercase"
                 @click="showModal = false"
        >
          Cancel
        </CButton>
        <CButton color="primary" class="mx-auto text-uppercase btn_yes"
                 @click="save()"
        >
          Yes
        </CButton>
      </div>
    </CCardBody>
    <template #footer-wrapper><span> </span></template>
  </CModal>
</template>

<script>
export default {
  name: "DefaultConfirmActionModal",
  data() {
    return {
      item: null,
      showModal: false,
      step: 1,
      finished: false,
    }
  },
  methods: {
    open(item) {
      this.showModal = true;
      this.item = item;
    },
    save() {
      this.showModal = false;
      this.$emit('confirmed', this.item);
      this.item = null;
    },
    closeModal() {
      this.$emit('modal:close');
    }
  }
}
</script>

<style scoped>

</style>