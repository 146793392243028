<template>
  <CModal
      :show.sync="showModal"
      :size="'sm'"
      centered
      @update:show="closeModal"
  >
    <template #header-wrapper>
      <CCardHeader>
        <h4 class="font-weight-bold  px-3">
          New Video
        </h4>
      </CCardHeader>
    </template>

    <CCardBody class="py-0 ">
      <CSelect :value="form.category"
               label="Category"
               :options="dataForSelect"
               :addInputClasses="{'is-invalid': hasError('category')}"
               @update:value="updateValue"
               :invalid-feedback="getError('category')"></CSelect>
      <CInput v-model="form.video_id"
              label="Vimeo ID"
              :addInputClasses="{'is-invalid': hasError('video_id')}"
              :invalid-feedback="getError('video_id')"></CInput>
      <div class="d-flex mt-3 align-items-center justify-content-between">
        <CButton color="secondary" variant="outline" class=" text-uppercase"
                 @click="showModal = false"
        >
          Cancel
        </CButton>
        <CButton color="primary" class=" text-uppercase"
                 @click="submit()"
        >
          Save
        </CButton>
      </div>
    </CCardBody>
    <template #footer-wrapper><span> </span></template>
  </CModal>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: "AddVideoModal",
  mixins: [hasApiValidation],
  props: {
    categories: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      showModal: false,
      step: 1,
      finished: false,
      form: {
        video_id: '',
        category: null,
      },
      item: null
    }
  },
  computed: {
    dataForSelect() {
      return [{
        value: 'null',
        text: 'Select Category',
        video: 'Select Category',
        label: 'Select Category',
      }, ...this.categories.map(item => {
        return {
          value: item.id,
          text: item.name,
          name: item.name,
          label: item.name
        }
      })]
    },
  },
  methods: {
    open() {
      this.showModal = true;
    },
    updateValue(val) {
      this.form.category  = val;
    },
    submit() {
      if (!!this.item) {
        this.update();
      } else {
        this.save();
      }
    },
    update() {
      this.setErrors({});
      this.$http.put('/help-videos/' + this.item.id, this.form)
          .then(() => {
            this.showModal = false;
            this.$emit('updated');
            this.form = {
              video_id: '',
              category: null,
            }
            this.item = null;
          })
          .catch(({response}) => {
            this.setErrors(response.data.errors);
            this.$noty.error(response.data.message);
          })
    },
    save() {
      this.setErrors({});
      this.$http.post('/help-videos', this.form)
          .then(() => {
            this.showModal = false;
            this.$emit('created');
            this.form = {
              video_id: '',
              category: null,
            }
            this.item = null;
          })
          .catch(({response}) => {
            this.setErrors(response.data.errors);
            this.$noty.error(response.data.message);
          })
    },
    closeModal() {
      this.setErrors({});
      this.item = null;
      this.form = {
        video_id: '',
        category: null,
      }
      this.$emit('modal:close');
    }
  }
}
</script>

<style scoped>

</style>