<template>
  <div>
    <nav class="nav nav-pills mb-3">
      <router-link :to="{name:'SpinStudioLanguages'}" class="mr-3 nav-link" >SpinStudio</router-link>
    </nav>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Languages",
  created() {
  }
}
</script>

<style scoped>

</style>