<template>
    <CCard>
        <CCardHeader class="d-flex ">
            <h2 v-if="!id" class="mb-0">New Collection</h2>
            <h2 v-else class="mb-0">Edit Collection</h2>
        </CCardHeader>
        <CCardBody>
            <form @submit.prevent="submit" autocomplete="off" v-if="(!this.id || !this.loading)">
                <CRow>
                    <CCol lg="6">
                        <CInput
                            label="Title"
                            v-model="new_collection.title"
                            :addInputClasses="{ 'is-invalid': hasError('title') }"
                            :invalid-feedback="getError('title')"
                        ></CInput>
                        <CCard class="mb-3" style="height: 25rem;">
                            <CCardTitle>Image</CCardTitle>
                            <CCardImg orientation="top" v-if="new_collection.image_base64 || new_collection.image_url" :src="new_collection.image_base64 || new_collection.image_url"  style="height: 20rem;"/>
                            <CCardHeader>
                                <input type="file" label="Image" @input="onFileChange($event)" />
                            </CCardHeader>
                        </CCard>
                        <div class="invalid-feedback d-block" v-if="hasError('image')">
                            {{ getError("image") }}
                        </div>
                       <CInput label="Release Date" v-if="(this.id)" v-model="new_collection.release_at"/>
                    </CCol>
                    <CCol lg="6">
                        <label>Description</label>
                        <editor 
                            :api-key="tiny_key" 
                            v-model="new_collection.description" 
                            :init="{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | help'
                            }"
                        />
                        <div
                            class="invalid-feedback d-block"
                            v-if="hasError('description')"
                        >
                            {{ getError("description") }}
                        </div>
                    </CCol>
                </CRow>
                <CRow>
                  
                    <CCol lg="6">
                        
                    </CCol>
                </CRow>
                
                <CButton type="submit" color="primary" class="mt-3">Save</CButton>
            </form>
            <div v-else class="mb-3">
                <CSpinner style="width:2rem;height:2rem;" color="primary" grow />
            </div>
        </CCardBody>
    </CCard>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";
import Editor from "@tinymce/tinymce-vue";

export default {
    name: "NewCollection",
    mixins: [hasApiValidation],
    props: ['id','currentPage'],
    components:{
        editor: Editor,
    },
    data() {
        return {
            new_collection: {
                title: '',
                description: '',
                release_at: null,
                image_base64: null,
                image_url: null,
                image: null,
                items: []

            },
            items: null,
            tiny_key: process.env.VUE_APP_TINY_KEY,
            loading: false
        };
    },
    mounted(){
        if (this.id){
            this.loading = true;
            this.fetchCollection(this.id);
        }
    },
    methods: {
        setCollection(data){
            this.new_collection = {
                ...this.new_collection,
                ...data
            };
           
        },
        fetchCollection(id){
            this.$http.get("/collections/"+ id)
            .then(({data})=> {
                this.setCollection(data);
            })
            .catch(({response})=>{
                this.$router.push({name: 'CollectionsList'}).then(()=>{
                    if (response && response.data && response.data.message) {
                        this.$noty.error(response.data.message);
                    }
                });
            })
            .finally(()=>{
                this.loading = false;
            });
        },
        update(){
            this.loading = true;
            const formData = new FormData();
            formData.append('title', this.new_collection.title);
            formData.append('description', this.new_collection.description);
            formData.append('status_id', 1);
            if (this.new_collection.image){
                formData.append('image', this.new_collection.image);
            }

         
            this.$http.post("/collections/" + this.id, formData)
            .then(({data})=> {
                this.loading = false;
                this.$noty.success("Updated");
                this.$router.push({name: 'CollectionsList',  params: {currentPage: this.currentPage} });
            })
            .catch(({response})=>{
                this.loading = false;
                this.$noty.error(response.data.message);
                this.setErrors(response.data.errors);
            });
        },
        submit() {
            this.setErrors({});
            if (this.id) {
                this.update();
          } else {
            this.save();
          }
        },
        save() {
            this.loading = true;
            const formData = new FormData();
            formData.append('title', this.new_collection.title);
            formData.append('description', this.new_collection.description);
            formData.append('status_id', 1);
            if (this.new_collection.image){
                formData.append('image', this.new_collection.image);
            }

            
            this.$http.post("/collections", formData)
            .then(({data})=>{
                this.loading = false;
                this.$noty.success("Created");
                this.$router.push({name: 'CollectionsList'});

            })
            .catch(({response})=>{
                this.loading = false;
                this.$noty.error(response.data.message);
                this.setErrors(response.data.errors);
            });
            
        },
        onFileChange(event) { 
            let file = event.target.files[0];
            if (file) { 
                const reader = new FileReader(); 
                reader.onload = (e) => { 
                    this.new_collection.image_base64 = e.target.result; 
                }; 
                reader.readAsDataURL(file); 

                this.new_collection.image = file;
            } 
        }
    }
}

</script>